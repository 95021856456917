import React from 'react';
import {
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { QuoteCommission } from '../../../../services/models/quote-commission';
import { NumberField, SelectField } from '../../../../components/form/fields';
import { useAppSelector } from '../../../../store/hooks';
import { selectAdvisor } from '../../../../features/application-slice';
import { CommissionDetailsAccordion } from '../../../../components/commission-details';

export interface EditableCommissionDetailsProps {
  productId: string;
  commission: QuoteCommission | null | undefined;
  onChangeCommitted: (name: string, value: unknown) => unknown;
}

function EditableCommissionDetails({ productId, commission, onChangeCommitted }: EditableCommissionDetailsProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const allowedCommissionStyles = useAppSelector(selectAdvisor).allowedCommissionStyles ?? [];
  const commissionStyle = watch('commission.style');
  return (
    <CommissionDetailsAccordion productId={productId} commission={commission}>
      <SelectField
        name="commission.style"
        options={allowedCommissionStyles}
        labelTranslationBasePath="common.commissionStyle"
        label={t('components.commissionDetails.commissionStyle')}
        sx={{ minWidth: 180 }}
        onChangeCommitted={onChangeCommitted}
        readOnly={allowedCommissionStyles.length === 1 && allowedCommissionStyles[0] === commissionStyle}
      />
      <NumberField
        name="commission.initialSacrificePercentage"
        label={t('components.commissionDetails.initialSacrificePercentage')}
        endAdornment={<InputAdornment position="end" disablePointerEvents>%</InputAdornment>}
        inputProps={{ maxLength: 3 }}
        sx={{ width: 90 }}
        min={0}
        max={100}
        onChangeCommitted={onChangeCommitted}
      />
      <NumberField
        name="commission.renewalSacrificePercentage"
        label={t('components.commissionDetails.renewalSacrificePercentage')}
        endAdornment={<InputAdornment position="end" disablePointerEvents>%</InputAdornment>}
        inputProps={{ maxLength: 3 }}
        sx={{ width: 90 }}
        min={0}
        max={100}
        onChangeCommitted={onChangeCommitted}
      />
    </CommissionDetailsAccordion>
  );
}

export default EditableCommissionDetails;
