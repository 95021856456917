import * as yup from 'yup';
import { ApplicationDto } from './models/application-dto';
import { unsatisfiedClientDetails } from './enquiry-helpers';
import { isValid } from './product-helpers';
import { ProductCode } from './models/product-code';
import { ApplicationStatus } from './models/application-status';
import { EnquiryCollation } from './models/enquiry-collation';
import { ApplicantDto } from './models/applicant-dto';
import { Document } from './models/document';
import { ProductDefinition } from './models/product-definition';
import { ProductDto } from './models/product-dto';
import { ProductQuoteDecision } from './models/product-quote-decision';
import { AddressDto } from './models/address-dto';
import { AddressLoqateDto } from './bff/models/address-loqate-dto';
import { OperationType } from './models/operation-type';
import { MedicalDetailsDto } from './models/medical-details-dto';
import { Enquiry } from './models/enquiry';
import { StatusDto } from './models/status-dto';
import { ApplicantStatusDto } from './models/applicant-status-dto';
import { SectionStatus } from './models/section-status';
import { ContactDetailsDto } from './models/contact-details-dto';
import { Operation } from './models/operation';
import { isFormEmpty } from '../components/form/form.utils';
import { ProgressStatus } from './models/progress-status';
import { PolicyStatus } from './models/policy-status';
import { isUnfavorableDecision } from './decision-helpers';
import { TotalsValue } from './bff/models/totals-value';
import { numberToMoney, parseServerDate } from '../utils/converters';
import { ActivatedProductDto } from './bff/models/activated-product-dto';
import { OptionDto } from './bff/models/option-dto';
import { ToolSettings } from './models/tool-settings';
import { isCollegue } from './auth-api';

export const basicClientDetailsValidationSchema = yup.object({
  gender: yup.string().required('Required'),
  smokerStatus: yup.string().required('Required'),
  dateOfBirthOrAge: yup.mixed().test('dateOfBirth', 'Date of birth or age is required', (_, context) => {
    const { age, dateOfBirth } = context.parent;
    return !!dateOfBirth || !!age;
  }),
});

export const postcodeValidationSchema = yup.string()
  .required('Required')
  .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Invalid postcode')
  .matches(/^(?!(GY|JE|IM)).*/i, 'The Exeter does not support applications from the Channel Islands or Isle of Man.');

export const fullClientDetailsValidationSchema = yup.object({
  title: yup.mixed().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  gender: yup.string().required('Required'),
  smokerStatus: yup.string().required('Required'),
  dateOfBirth: yup.string().required('Please enter a valid date'),
  postcode: postcodeValidationSchema,
});

export const amraConsentValidationSchema = yup.object({
  amraConsent: yup.boolean().required('Required'),
  previewRequired: yup.string().required('Required'),
});

export const bankDetailsValidationSchema = yup.object({
  accountName: yup.string()
    .required('Name on the account is required'),
  accountCode: yup.string()
    .required('Account number is required')
    .length(8, 'Account number must be exactly 8 digits')
    .matches(/^\d{8}$/, 'Invalid account number format'),
  sortCode: yup.string()
    .required('Sort code is required')
    .length(6, 'Sort code must be exactly 6 digits')
    .matches(/^\d{6}$/, 'Invalid sort code format'),
  collectionDay: yup.number()
    .required('Preferred collection day is required')
    .min(1)
    .max(28),
});

// eslint-disable-next-line max-len
const phoneRegExp = /^(?:(?:\+|0{0,2})44\s?|(?:\((?=\+?44)(?:\+?44\)?)))?0?(?:(?:\(\d{1,5}\)|\d{1,5})(?:\s|-|\.)?\d{1,5}(?:\s|-|\.)?\d{1,5}|(?:\d{4}(?:\s|-|\.)?\d{3}(?:\s|-|\.)?\d{3}))$/;
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const postcodeRegExp = /^([A-Z]{1,2}\d{1,2}[A-Z]?)?(\d[A-Z]{2})$/i;

export const amraDoctorsDetailsSchema = yup.object({
  gpName: yup.string().notRequired(),
  practiceName: yup.string().required('Surgery name required'),
  address1: yup.string().required('Address line 1 required'),
  address2: yup.string(),
  cityName: yup.string().required('Town/City required'),
  county: yup.string(),
  postcode: yup.string().required('Postcode required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Please enter a valid surgery phone number').required('Surgery phone number required'),
});

export const contactDetailsValidationSchema = yup.object({
  address1: yup.string().required('Required'),
  cityName: yup.string().required('Required'),
  postcode: postcodeValidationSchema,
  emailAddress: yup.string().matches(emailRegExp, 'Please enter a valid email address').required('Email address required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Please enter a valid phone number').required('Phone number required'),
});

export function isPreSalesAppliction(applicationStatus: string | null | undefined) {
  return applicationStatus === ApplicationStatus.PreSale;
}

export function isUMEApplication(origin: string | null | undefined): boolean {
  return origin?.toUpperCase() === 'UME';
}

export function getApplicantAddress(application: ApplicationDto, applicant: ApplicantDto | null | undefined): AddressDto | null {
  const addressId = applicant?.contactDetails?.addressId;
  return addressId ? application.addresses?.find((a) => a.id === addressId) || null : null;
}

export function getApplicantMedicalDetailsAddress(application: ApplicationDto, applicant: ApplicantDto | null | undefined): AddressDto | null {
  const addressId = applicant?.medicalDetails?.addressId;
  return addressId ? application.addresses?.find((a) => a.id === addressId) || null : null;
}

export function isValidPostcode(postcode: string): boolean {
  return !!postcode.match(postcodeRegExp) ?? false;
}

export function formatPostcode(postcode: string): string {
  const match = postcode.replace(/\s+/g, '').match(postcodeRegExp);
  return match ? `${match[1]} ${match[2]}`.toUpperCase() : postcode;
}

export function hasPostcodeOnly(address: AddressDto): boolean {
  return !!address.postcode && isFormEmpty(['address1', 'address2', 'address3', 'cityName', 'county'], address);
}

export function isApplicantValid(application: ApplicationDto, applicant: ApplicantDto): boolean {
  const isPreSales = isPreSalesAppliction(application.status);
  if (isPreSales) {
    return basicClientDetailsValidationSchema.isValidSync(applicant);
  }
  const postcode = getApplicantAddress(application, applicant)?.postcode;
  return fullClientDetailsValidationSchema.isValidSync({ ...applicant, postcode });
}

export function isApplicantMissingLifestyleAnswers(applicant: ApplicantDto | null, enquiries: Record<string, Enquiry>): boolean {
  return applicant ? !!unsatisfiedClientDetails(enquiries[applicant.enquiryId]) : false;
}

export function isApplicantMissingContactDetails(application: ApplicationDto, applicant: ApplicantDto | null) {
  const address = getApplicantAddress(application, applicant);
  return applicant && !contactDetailsValidationSchema.isValidSync({ ...applicant.contactDetails, ...address });
}

export enum ApplicationStatusSectionName {
  PaymentDetails = 'Payment details',
  PolicySetup = 'Policy setup',
}

export function isApplicationSectionComplete(sectionName: ApplicationStatusSectionName, applicationStatus: StatusDto): boolean {
  return applicationStatus.application.sections?.find((section) => section.name === sectionName)?.status === SectionStatus.Complete;
}

export function isApplicationActivationReady(applicationStatus: StatusDto): boolean {
  return applicationStatus.application.policyStatus === PolicyStatus.CanBeActivated;
}

export function getApplicantStatus(applicationStatus: StatusDto, applicantId: string): ApplicantStatusDto | null {
  return applicationStatus.applicants?.find((s) => s.id === applicantId) ?? null;
}

export enum ApplicantStatusSectionName {
  PersonalInformation = 'Personal information',
  ContactInformation = 'Contact information',
  AccessToMedicalRecords = 'Access to medical records',
}

export function getApplicantSectionStatus(sectionName: ApplicantStatusSectionName, applicantStatus: ApplicantStatusDto | null): SectionStatus {
  return applicantStatus?.sections?.find((section) => section.name === sectionName)?.status ?? SectionStatus.Incomplete;
}

export function getAmraProgressStatus(applicant: ApplicantDto, applicationStatus: StatusDto): ProgressStatus {
  if (!applicant.medicalDetails?.amraConsent && typeof applicant.medicalDetails?.previewRequired !== 'boolean') {
    return ProgressStatus.NotStarted;
  }
  const applicantStatus = getApplicantStatus(applicationStatus, applicant.id);
  const sectionStatus = getApplicantSectionStatus(ApplicantStatusSectionName.AccessToMedicalRecords, applicantStatus);
  switch (sectionStatus) {
    case SectionStatus.Complete: return ProgressStatus.Complete;
    case SectionStatus.Incomplete: return ProgressStatus.Incomplete;
    default: return ProgressStatus.NotStarted;
  }
}

export function getUndwerwritingDeclarationStatus(enquiry: Enquiry): ProgressStatus {
  return !enquiry.isOpen ? ProgressStatus.Complete : ProgressStatus.NotStarted;
}

export function isPostUnderwritingDeclarationEnabled(applicantStatus: ApplicantStatusDto | null, enquiry: Enquiry): boolean {
  return !!enquiry?.isCloseable
    && getApplicantSectionStatus(ApplicantStatusSectionName.ContactInformation, applicantStatus) === SectionStatus.Complete
    && getApplicantSectionStatus(ApplicantStatusSectionName.AccessToMedicalRecords, applicantStatus) === SectionStatus.Complete;
}

export function firstValidProduct(isPreSales: boolean, application: ApplicationDto) {
  return application.products.find((product) => isValid(isPreSales, product));
}

export function hasInvalidProduct(isPreSales: boolean, application: ApplicationDto): boolean {
  return application.products.some((product) => !isValid(isPreSales, product));
}

export function hasIPProduct(application: ApplicationDto) {
  return application.products.some((product) => product.code === ProductCode.INCOME_PROTECTION);
}

export function getEnquiryCollation(application: ApplicationDto): EnquiryCollation | undefined {
  return isPreSalesAppliction(application.status) ? EnquiryCollation.DepthFirst : undefined;
}

export function getApplicantName({ firstName, lastName }: Pick<ApplicantDto, 'firstName' | 'lastName'>): string {
  return [firstName, lastName].filter((s) => !!s).join(' ');
}

export function getApplicantFullName({ title, firstName, lastName }: Pick<ApplicantDto, 'title' | 'firstName' | 'lastName'>): string {
  return [title, firstName, lastName].filter((s) => !!s).join(' ');
}

export function getApplicationDocuments(products: ProductDto[] | ProductQuoteDecision[], productDefinitions: ProductDefinition[]): Document[] {
  return productDefinitions
    .filter((productDefinition) => products.some((product) => product.code === productDefinition.productCode))
    .flatMap((productDefinition) => productDefinition.documents?.map((document) => ({
      ...document,
      name: `${productDefinition.name} ${document.name}`,
    })) ?? []);
}

export function applicantProductDecision(applicantId: string, product: ProductQuoteDecision): string | null {
  return product.applicantDecisions.find((applicantDecision) => applicantDecision.applicantId === applicantId)?.decision ?? null;
}

export function applicantHasAcceptedProducts(applicantId: string, products: ProductQuoteDecision[]): boolean {
  return products
    .some((product) => {
      const applicantDecision = applicantProductDecision(applicantId, product);
      if (!isUnfavorableDecision(product.decision)
        && !isUnfavorableDecision(applicantDecision)) {
        return true;
      }
      return false;
    });
}

export function formatLoqateAddress(address: AddressLoqateDto): AddressDto {
  return {
    address1: address.line1,
    address2: address.line2,
    address3: address.line3,
    cityName: address.city,
    county: address.province,
    postcode: address.postalCode,
  };
}

export function getContactDetailsPatchOperations(contactDetails: ContactDetailsDto): Operation[] {
  return ['addressId', 'emailAddress', 'phoneNumber'].map((name) => ({
    op: OperationType.Add,
    path: `/contactDetails/${name}`,
    value: contactDetails[name as keyof ContactDetailsDto],
  }));
}

export function getAddressPatchOperations(address: AddressDto): Operation[] {
  return ['address1', 'address2', 'cityName', 'county', 'postcode'].map((name) => ({
    op: OperationType.Add,
    path: `/${name}`,
    value: address[name as keyof AddressDto],
  }));
}

export function getAmraDoctorsPatchOperations(medicalDetails: MedicalDetailsDto): Operation[] {
  return ['addressId', 'gpName', 'phoneNumber', 'practiceName'].map((name) => ({
    op: OperationType.Add,
    path: `/medicalDetails/${name}`,
    value: medicalDetails[name as keyof MedicalDetailsDto],
  }));
}

export function isDocdorDetailsReadOnly(application: ApplicationDto): boolean {
  return !isCollegue() && (!!application.dates?.lastReferred || application.status === ApplicationStatus.Underwriting);
}

export function isApplicationReadOnly(application: ApplicationDto): boolean {
  return !isCollegue() && application.status === ApplicationStatus.Underwriting;
}

const totalsFormatOptions: Partial<Intl.NumberFormatOptions> = { style: 'decimal', minimumFractionDigits: 2 };

export function getTotalsValue(totalsValue: TotalsValue): string | null {
  if (totalsValue.min !== null && totalsValue.min > 0 && totalsValue.min === totalsValue.max) {
    return numberToMoney(totalsValue.min, totalsFormatOptions);
  }
  if (totalsValue.min !== null && totalsValue.min && totalsValue.max !== null) {
    return `${numberToMoney(totalsValue.min, totalsFormatOptions)} - ${numberToMoney(totalsValue.max, totalsFormatOptions)}`;
  }
  return null;
}

export function isValidPolicyStartDate(date: string, expiryDate: string | null | undefined): boolean {
  return parseServerDate(date)?.startOf('day').isSameOrBefore(parseServerDate(expiryDate)?.startOf('day')) ?? false;
}

export interface ApplicationQueryParams {
  from?: number,
  size?: number,
  orderBy?: string | null,
  descending?: boolean | null,
  applicationId?: string | null,
  status?: ApplicationStatus | null,
}

export function getActivatedProductPolicyRef(productId: string, activatedProducts: ActivatedProductDto[]): string | null {
  return activatedProducts.find((activatedProduct) => activatedProduct.productId === productId)?.policyRef ?? null;
}

export function hasCompleteApplicant(applicantStatus: ApplicantStatusDto | undefined, enquiry: Enquiry | undefined): boolean {
  if (applicantStatus && enquiry && !applicantStatus.sections?.some((section) => section.status === SectionStatus.Incomplete) && enquiry.isOpen === false) {
    return true;
  }
  return false;
}

export function hasIncompleteInactiveApplicant(
  activeApplicant: ApplicantDto,
  applicantStatus: ApplicantStatusDto[],
  enquiries: Record<string, Enquiry>,
): boolean {
  if (!hasCompleteApplicant(applicantStatus.find((applicant) => applicant.id === activeApplicant.id), enquiries[activeApplicant.enquiryId])) {
    return false;
  }
  return applicantStatus.some(
    (status) => status.id !== activeApplicant.id
      && !hasCompleteApplicant(status, Object.values(enquiries).find((enquiry) => enquiry.enquiryId !== activeApplicant.enquiryId)),
  ) ?? false;
}

export function showIncompleteApplicantWarning(
  activeApplicant: ApplicantDto | null,
  applicationStatus: StatusDto,
  enquiries: Record<string, Enquiry>,
): boolean {
  if (!activeApplicant || !applicationStatus.applicants) {
    return false;
  }
  return hasIncompleteInactiveApplicant(activeApplicant, applicationStatus.applicants, enquiries);
}

export function getApplicantTitleFromOptions(applicant: ApplicantDto, options: OptionDto[]): string | null | undefined {
  return options.find((option) => option.name === applicant.title)?.text ?? applicant.title;
}

export function getPreSaleToolProductCodes(tool: ToolSettings | undefined, isInternal: boolean): string[] {
  return tool?.productCodes?.[isInternal ? 'internal' : 'external'] ?? [];
}
