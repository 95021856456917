import * as yup from 'yup';
import { isAxiosError } from 'axios';
import { FieldValues, UseFormResetField } from 'react-hook-form';
import { Question } from '../../services/models/question';
import { Section } from '../../services/models/section';
import { areEqualAnswers, getQuestionAnswer, getSectionQuestions } from '../../services/enquiry-helpers';

export function formPath(question: Question) {
  return question.path ? question.path.replaceAll(/[[\]|]/g, '-') : '';
}

export function getFormValuesFor(
  questions: Question[],
  additionalAnswers?: Record<string, string | string[] | null>,
): Record<string, string | string[] | null> {
  return questions.reduce((values, question) => ({
    ...values,
    [formPath(question)]: getQuestionAnswer(question),
  }), { ...additionalAnswers });
}

export function updateForm(
  currentValues: FieldValues,
  currentDefaults: FieldValues | undefined,
  resetField: UseFormResetField<any>,
  setValue: (key: any, value: any) => void,
  newValues: FieldValues,
) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(newValues)) {
    const currentValue = currentValues[key];
    const currentDefault = currentDefaults?.[key];
    if (!areEqualAnswers(currentDefault, value)) {
      resetField(key, { defaultValue: value });
      const modified = !areEqualAnswers(currentValue, currentDefault);
      setValue(key, modified ? currentValue : value);
    }
  }
}

/**
 * Return an array of required questions
 */
export const getRequiredFieldsFromQuestions = (
  questions: Question[],
): Question[] => questions; // temp until required type confirmed

/**
 * Get the number value of a string
 */
export const getNumberValue = (fieldValue: string | undefined): number => {
  if (fieldValue) {
    const value = parseFloat(fieldValue);
    return Number.isNaN(value) ? 0 : value;
  }
  return 0;
};

/**
 * Takes an array of questions and returns only
 * questions tagged as Group/Percent questions
 */
export const getGroupPercentageQuestions = (
  questions: Question[],
): Question[] => questions.filter((question: Question) => question.definition?.tags?.indexOf('GROUP/PERCENTAGE') !== -1);

export const setNumberValidation = (question: Question): yup.NumberSchema => {
  if (typeof question.definition?.upperBound === 'number'
    && typeof question.definition?.lowerBound === 'number') {
    return yup
      .number()
      .max(question.definition.upperBound, 'Too high')
      .min(question.definition.lowerBound, 'Too low')
      .required('Required');
  }
  if (typeof question.definition?.upperBound === 'number') {
    return yup.number().max(question.definition.upperBound, 'Too high').required('Required');
  }
  if (typeof question.definition?.lowerBound === 'number') {
    return yup.number().min(question.definition.lowerBound, 'Too low').required('Required');
  }
  return yup.number().required('Required');
};

export const setDateValidation = (question: Question): yup.DateSchema => {
  if (question.definition?.type === 'FUTURE_DATE') {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return yup
      .date()
      .min(date)
      .required('Required');
  }
  if (question.definition?.type === 'PAST_DATE') {
    return yup
      .date()
      .max(new Date())
      .required('Required');
  }
  return yup
    .date()
    .required('Required');
};

export const setOptionGroupValidation = (
  question: Question,
): yup.StringSchema => {
  if (question.definition?.isMultiValued) {
    return yup.lazy((val) => {
      if (Array.isArray(val)) {
        return yup.array().min(1).required('Required');
      }
      return yup.string().required('Required');
    }) as any;
  }
  return yup.string().required('Required');
};

export const setStringValidation = (): yup.StringSchema => yup.string().required('Required');

export const setValiation = (question: Question) => {
  switch (question.definition?.type) {
    case 'NUMBER':
    case 'INTEGER': return setNumberValidation(question);
    case 'PAST_DATE':
    case 'FUTURE_DATE':
    case 'DATE': return setStringValidation();
    case 'OPTION_GROUP': return setOptionGroupValidation(question);
    case 'OPTION_BACKED': return setOptionGroupValidation(question);
    default: return setStringValidation();
  }
};

/**
 * Takes an array of questions and returns a yup shape
 * that controls the form validation
 */
export const generateSchemaShapeFromQuestions = (questions: Omit<Question, '__typename'>[]): yup.ObjectShape => {
  const requiredQuestions = getRequiredFieldsFromQuestions(questions);
  const questionShape = requiredQuestions
    .reduce((a, question: Question) => ({
      ...a,
      [formPath(question)]: setValiation(question),
    }), {});

  return questionShape as yup.ObjectShape;
};

/**
 * Constructs a yup validation schema from a section
 * for form validation
 */
export const getValidationSchemaFromSection = (
  section: Section,
) => {
  const enquiryLineQuestionsShape = generateSchemaShapeFromQuestions(getSectionQuestions(section));
  return yup.object().shape(enquiryLineQuestionsShape);
};

/**
 * Returns a yup form resolver with a
 * schema validation from an array of sections
 */
export const getValidationSchemaFromSections = (sections: Section[]) => {
  const schemas = sections.map((section) => getValidationSchemaFromSection(section));
  const [first, ...rest] = schemas;
  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first,
  );
  return merged;
};

/**
 * Returns a yup form resolver with a schema validation
 */
export const getValidationSchemaForQuestions = (questions: Question[]) => yup.object().shape(generateSchemaShapeFromQuestions(questions));

function isFieldEmpty(field: string, values: any): boolean {
  const value = values[field];
  return value === undefined || value === null || value === '';
}

/**
 * Return true if form fields are null or empty
 */
export function isFormEmpty(fields: string[], values: any): boolean {
  return fields.every((field) => isFieldEmpty(field, values));
}

export function handleError(e: unknown, showError: (message: string) => void): void {
  if (isAxiosError(e)) {
    const { response } = e;
    const messages = (response?.data?.errors as any[] || []).map((error) => error.message);
    showError(messages[0] || '');
  }
}

export function handlePostcodeError(e: unknown, showError: (message: string) => void): void {
  if (isAxiosError(e)) {
    const { response } = e;
    if (typeof response?.data?.errors === 'object') {
      const message = Object.values(response.data.errors).flat()[0];
      showError(typeof message === 'string' ? message : '');
    }
  }
}

export function validateDigitsOnly(value: string) {
  return /^\d*$/.test(value);
}

export function yesNoToBoolean(value: string | null | undefined): boolean | null {
  switch (value) {
    case 'yes': return true;
    case 'no': return false;
    default: return null;
  }
}

export function booleanToYesNo(value: boolean | null | undefined): 'yes' | 'no' | null {
  switch (value) {
    case true: return 'yes';
    case false: return 'no';
    default: return null;
  }
}

export function stringToNumericEnum<T>(value: string | null | undefined): T | null {
  return value ? parseInt(value, 10) as T : null;
}
