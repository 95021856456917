import React, { useRef, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import BankDetails from './bank-details';
import { ApplicationDto } from '../../../../services/models/application-dto';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import useBusyState from '../../../../hooks/use-busy-state';
import { patchBankDetails } from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';
import { BankDetailsDto } from '../../../../services/models/bank-details-dto';
import { handleError } from '../../../../components/form/form.utils';
import { bankDetailsValidationSchema } from '../../../../services/application-helpers';
import applicationApi from '../../../../services/application-api';
import { ApplicantDto } from '../../../../services/models/applicant-dto';

const validStatusInformation = 'OK';

function isValidStatusCode(code: boolean | null | undefined): boolean {
  return !!code;
}

function isValidStatusInformation(statusInformation: string | null | undefined): boolean {
  return statusInformation === validStatusInformation;
}

const confirmationValidationSchema = yup.object({
  confirm: yup.boolean().required('Required').equals([true], 'Confirmation required'),
});

export interface PaymentDetailsProps extends CardProps {
  application: ApplicationDto;
  applicants: ApplicantDto[];
}

function PaymentDetails({ application, applicants, ...props }: PaymentDetailsProps) {
  const { t } = useTranslation();
  const queue = useRef(new SequentialTaskQueue()).current;
  const [busy, withBusyState] = useBusyState(queue);
  const [bankDetailsErrors, setBankDetailsErrors] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const bankDetailsForm = useForm({
    defaultValues: {
      ...application.bankDetails,
      bankAccountOwner: application.bankDetails?.bankAccountOwner,
      confirm: isValidStatusCode(application.bankDetails?.accountValidation),
    },
    resolver: yupResolver(bankDetailsValidationSchema.concat(confirmationValidationSchema)) as any,
    mode: 'onBlur',
  });
  const bankDetailsValidated = isValidStatusCode(application.bankDetails?.accountValidation);
  const { errors, isValid } = bankDetailsForm.formState;
  const formErrors = Object.values(errors).map((e) => e.message).filter((e) => !!e) as string[];

  const updateBankDetails = withBusyState(queue.sequentialize(async (name: string, value: unknown) => {
    const key = name as keyof BankDetailsDto;
    try {
      const newValue = await dispatch(patchBankDetails(application.id, name, value));
      bankDetailsForm.resetField(key, { defaultValue: newValue, keepTouched: true });
      bankDetailsForm.setValue(key, newValue, { shouldValidate: true, shouldDirty: true });
    } catch (e) {
      handleError(e, (message) => {
        bankDetailsForm.clearErrors(key);
        bankDetailsForm.setError(key, { message });
        setBankDetailsErrors([message]);
      });
    }
  }));

  const validateBankDetails = withBusyState(queue.sequentialize(async () => {
    try {
      setBankDetailsErrors([]);
      const { accountCode, sortCode } = bankDetailsForm.getValues();
      const result = (await applicationApi.validateBankAccount(accountCode!, sortCode!)).items?.[0];
      const status = result?.statusInformation;
      if (result?.isCorrect) {
        await dispatch(patchBankDetails(application.id, 'accountValidation', isValidStatusInformation(status)));
      } else {
        if (application.bankDetails?.accountValidation) {
          await dispatch(patchBankDetails(application.id, 'accountValidation', null));
        }
        const translatedError = t(`errors.bankAccountValidation.${status}`, result ?? {});
        const message = !translatedError || translatedError.startsWith('errors.')
          ? t('errors.bankAccountValidation.UknownError', { status })
          : translatedError;
        setBankDetailsErrors([message]);
      }
    } catch (e) {
      handleError(e, (message) => {
        setBankDetailsErrors([message]);
      });
    }
  }));

  const modifyValidatedAccount = withBusyState(queue.sequentialize(async () => {
    try {
      await dispatch(patchBankDetails(application.id, 'accountValidation', null));
    } catch (e) {
      handleError(e, (message) => {
        setBankDetailsErrors([message]);
      });
    }
  }));

  return (
    <Card {...props}>
      <CardHeader
        title="Payment details"
        titleTypographyProps={{
          variant: 'h3',
        }}
        sx={{ pt: 3, pb: 3 }}
      />
      <CardContent>
        <FormProvider {...bankDetailsForm}>
          <BankDetails
            onChangeCommitted={updateBankDetails}
            onValidate={validateBankDetails}
            bankAccountOwner={application.bankDetails?.bankAccountOwner}
            applicants={applicants}
            busy={busy}
            isFormValid={isValid}
            errors={bankDetailsErrors.concat(formErrors)}
            validated={bankDetailsValidated}
            onModifyValidatedAccount={modifyValidatedAccount}
          />
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default PaymentDetails;
