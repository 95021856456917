import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import {
  useNavigate,
  generatePath,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  AvailableApplication,
  selectApplicationState,
  selectApplicationStatus,
  startApplication,
} from '../../../../features/application-slice';
import { hasApplicantPreSalesDecision } from '../../../../services/decision-helpers';
import { ApplicationStatus } from '../../../../services/models/application-status';
import { HeaderStack, ReferenceChip } from './application-layout.styles';
import StatusIndicator from '../status-indicator';
import {
  ActivePage,
  ApplicationPage,
  CompletePage,
  PolicySetupPage,
  ReviewPage,
  SearchPage,
} from '../../../pages';
import { UnderwriteMeEnquiryType } from '../../../../services/models/underwrite-me-enquiry-type';
import CustomReferenceChip from '../custom-reference-chip';
import documentApi from '../../../../services/document-api';
import DownloadDocumentButton from '../../../../components/download/download-document-button';
import { ProgressStatus } from '../../../../services/models/progress-status';
import { getAmraProgressStatus, getUndwerwritingDeclarationStatus } from '../../../../services/application-helpers';
import { ApplicationStep } from '../status-indicator/status-indicator';
import { selectOnboardingToolSettings, selectUser } from '../../../../features/settings-slice';
import AssignApplication from '../assign-application';
import { canUserAssignApplication, canUserUpdateQuoteDate } from '../../../../services/profile-helpers';
import useBreakpoint from '../../../../hooks/use-breakpoints';
import HeaderButton from './header-button';
import QuoteDate from '../quote-date';

const StyledHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  '& h5': {
    color: theme.palette.common.greyLight,
  },
  '& button > img': {
    marginLeft: theme.spacing(1),
  },
  '& button:disabled > img': {
    opacity: 0.2,
  },
}));

function getApplicationTitle(applicationStatus: string) {
  switch (applicationStatus) {
    case ApplicationStatus.PreSale: return 'components.applicationHeader.status.preSale';
    case ApplicationStatus.Quote: return 'components.applicationHeader.status.quote';
    case ApplicationStatus.Apply: return 'components.applicationHeader.status.apply';
    case ApplicationStatus.Decision: return 'components.applicationHeader.status.decision';
    default: return '';
  }
}

function getStatusActiveStep(applicationStatus: string | null | undefined, defaultActiveStep?: number) {
  if (defaultActiveStep) {
    return defaultActiveStep;
  }
  switch (applicationStatus) {
    case ApplicationStatus.Quote: return 0;
    case ApplicationStatus.Apply: return 1;
    case ApplicationStatus.Underwriting: return 2;
    case ApplicationStatus.Decision: return 2;
    case ApplicationStatus.Active: return 2;
    default: return 0;
  }
}

function getApplicationInProgressStatus(application: AvailableApplication): ProgressStatus {
  if (application.applicationStatus.application.status === ApplicationStatus.Quote) {
    return ProgressStatus.NotStarted;
  }
  if (Object.values(application.enquiries).some((enquiry) => !enquiry.isCloseable && enquiry.isOpen)) {
    return ProgressStatus.NotStarted;
  }
  if (application.application.applicants.some((applicant) => getAmraProgressStatus(applicant, application.applicationStatus) !== ProgressStatus.Complete)
    || Object.values(application.enquiries).some((enquiry) => getUndwerwritingDeclarationStatus(enquiry) !== ProgressStatus.Complete)) {
    return ProgressStatus.Complete;
  }
  return ProgressStatus.Complete;
}

function getUWStatus(status: ApplicationStatus | null): ProgressStatus {
  if (status === ApplicationStatus.Underwriting) {
    return ProgressStatus.Incomplete;
  }
  if (status === ApplicationStatus.Decision || status === ApplicationStatus.Active) {
    return ProgressStatus.Complete;
  }
  return ProgressStatus.NotStarted;
}

function ApplicationHeader() {
  const { t } = useTranslation();
  const state = useAppSelector(selectApplicationState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isWideScreen = useBreakpoint('lg');
  const applicationStatus = useAppSelector(selectApplicationStatus);

  let hasDecision = false;
  let quoteStatus = ProgressStatus.NotStarted;
  let applicationInProgressStatus = ProgressStatus.NotStarted;
  let uwStatus = ProgressStatus.NotStarted;
  if (state.status === 'available') {
    const { application, preSalesDecisions } = state;
    const firstApplicant = application.applicants![0];
    hasDecision = hasApplicantPreSalesDecision(firstApplicant.id, preSalesDecisions);
    quoteStatus = applicationStatus !== ApplicationStatus.Quote ? ProgressStatus.Complete : ProgressStatus.NotStarted;
    applicationInProgressStatus = getApplicationInProgressStatus(state);
    uwStatus = getUWStatus(applicationStatus);
  }

  const applicationId = state.status === 'available' ? state.application.id : null;
  const isPreSale = applicationStatus === ApplicationStatus.PreSale;
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled ?? false;
  const user = useAppSelector(selectUser);
  const showAssignButton = canUserAssignApplication(user);
  const showQuoteDateButton = canUserUpdateQuoteDate(user);

  const generatePresaleDocument = () => documentApi.downloadPresaleDocument(applicationId!, onboardingEnabled);

  const handleRestart = async () => {
    const application = await dispatch(startApplication(UnderwriteMeEnquiryType.PreSales));
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id || null }));
  };

  const handleSaveExit = () => {
    navigate(SearchPage.path);
  };

  const findMatchingPath = (path: string): boolean => {
    const regex = new RegExp(path.replace(/:[^\s/]+/g, '([\\w-]+)'));
    return regex.test(location.pathname);
  };

  const completeStepPaths = [ReviewPage.path, CompletePage.path, ActivePage.path] as string[];
  const lastStepPaths = [PolicySetupPage.path, ...completeStepPaths] as string[];
  const defaultActiveStep = lastStepPaths.some((path) => findMatchingPath(path)) ? 3 : undefined;

  const steps = [
    { status: quoteStatus, label: t('components.applicationHeader.statusIndicator.quote') },
    { status: applicationInProgressStatus, label: t('components.applicationHeader.statusIndicator.application') },
    { status: uwStatus, label: uwStatus === ProgressStatus.Incomplete ? t('components.applicationHeader.statusIndicator.underwriting') : t('components.applicationHeader.statusIndicator.offer') },
    { status: !completeStepPaths.some((path) => findMatchingPath(path)) ? ProgressStatus.NotStarted : ProgressStatus.Complete, label: t('components.applicationHeader.statusIndicator.setup') },
  ] as ApplicationStep[];

  return (
    <StyledHeader>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <HeaderStack inline>
          {applicationStatus && <Typography variant="h2">{t(getApplicationTitle(applicationStatus), '')}</Typography>}
          {applicationId && (
            <Stack direction="row" gap={1}>
              <ReferenceChip label={(
                <>
                  {isWideScreen && `${t('components.applicationHeader.ourRef')} `}
                  <strong data-testid="applicationId">{applicationId}</strong>
                </>
              )}
              />
              <CustomReferenceChip />
            </Stack>
          )}
        </HeaderStack>
        {!isPreSale && (
          <StatusIndicator
            steps={steps}
            activeStep={getStatusActiveStep(applicationStatus, defaultActiveStep)}
            sx={{ mr: 'auto', ml: 'auto' }}
            hideLabel={!isWideScreen}
          />
        )}
        <Stack direction="row" alignItems="center" gap={2}>
          {isPreSale && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleRestart}
                endIcon={<RefreshIcon />}
              >
                {t('components.applicationHeader.restartEnquiry')}
              </Button>
              <DownloadDocumentButton
                fileName={`Presale decision - ${applicationId}.pdf`}
                disabled={!hasDecision}
                label={t('components.applicationHeader.downloadDecision.label')}
                resourceName="Presale decision"
                errorMessage={t('components.applicationHeader.downloadDecision.error')}
                generateDocument={generatePresaleDocument}
              />
            </>
          )}
          {showQuoteDateButton && (
            <QuoteDate />
          )}
          {showAssignButton && applicationId && (
            <AssignApplication applicationId={applicationId} />
          )}
          <HeaderButton
            variant="outlined"
            color="secondary"
            onClick={handleSaveExit}
            icon={<SaveIcon />}
          >
            {t('components.applicationHeader.saveAndExit')}
          </HeaderButton>
        </Stack>
      </Stack>
    </StyledHeader>
  );
}

export default ApplicationHeader;
