import { toast } from 'react-toastify';

function toastError(message: string): void {
  toast(message, {
    position: 'bottom-left',
    type: 'error',
  });
}

function toastSuccess(message: string): void {
  toast(message, {
    position: 'bottom-left',
    type: 'success',
  });
}

export {
  toastError,
  toastSuccess,
};

export default toastError;
