import { UserDto } from './bff/models/user-dto';
import http from './http';

/**
 * Retrieve user
 */
async function getUser(userId: string): Promise<UserDto> {
  return http.get(`/api/v1/bff/profile/user/${userId}`);
}

const profileApi = {
  getUser,
};

export default profileApi;
