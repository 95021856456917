import React from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
// import StartToolPanel from './components/start-tool-panel';
import { Container, FeaturePanelContainer, ProductContainer } from './presales-intro.styles';
import { ApplicationPage } from '../pages';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectApplicationState,
  startApplication,
} from '../../features/application-slice';
import { UnderwriteMeEnquiryType } from '../../services/models/underwrite-me-enquiry-type';
import { Tool } from '../../services/models/tool';
import { selectPresalesToolSettings } from '../../features/settings-slice';
import HeroPanel from './components/hero-panel';
import FeaturePanel from './components/feature-panel';
import UWIcon from '../../assets/icons/uw-icon-lg.svg';
import ShieldIcon from '../../assets/icons/shield-icon-lg.svg';
import ReportIcon from '../../assets/icons/report-icon-lg.svg';
import ArrowIcon from '../../assets/icons/arrow-forward-icon.svg';
import ProductAvailabilityPanel from './components/product-availability-panel';
import { isCollegue } from '../../services/auth-api';
import { getPreSaleToolProductCodes } from '../../services/application-helpers';

const features = [
  {
    icon: UWIcon,
    title: 'components.preSalesIntro.featureUnderwriting.title',
    description: 'components.preSalesIntro.featureUnderwriting.description',
  },
  {
    icon: ShieldIcon,
    title: 'components.preSalesIntro.featureDecision.title',
    description: 'components.preSalesIntro.featureDecision.description',
  },
  {
    icon: ReportIcon,
    title: 'components.preSalesIntro.featureReport.title',
    description: 'components.preSalesIntro.featureReport.description',
  },
];

const products = [
  {
    id: 'NIPN',
    title: 'common.productType.ip',
    unavailableText: 'components.productAvailabilityPanel.comingSoon',
  },
  {
    id: 'RL',
    title: 'common.productType.realLife',
    unavailableText: 'components.productAvailabilityPanel.comingSoon',
  },
  {
    id: '110',
    title: 'common.productType.healthCare',
    unavailableText: 'components.productAvailabilityPanel.comingLater',
  },
];

function PreSalesIntro() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const applicationState = useAppSelector(selectApplicationState);
  const preSalesTool = useAppSelector(selectPresalesToolSettings);
  if (!preSalesTool) {
    return null;
  }

  const handleStartApplication = async (enquiryType: UnderwriteMeEnquiryType) => {
    const application = await dispatch(startApplication(enquiryType));
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id || null }));
  };

  const availableProducts = getPreSaleToolProductCodes(preSalesTool, isCollegue());

  return (
    <Container data-testid="container">
      <Stack sx={{ width: '100%' }}>
        <HeroPanel />
        <ProductContainer>
          <Stack direction="row" justifyContent="space-evenly">
            {products.map((product) => (
              <ProductAvailabilityPanel
                key={product.id}
                title={t(product.title)}
                unavailableText={product.unavailableText}
                enabled={availableProducts?.some((code) => code === product.id) ?? false}
              />
            ))}
          </Stack>
          {preSalesTool.enabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStartApplication(UnderwriteMeEnquiryType.PreSales)}
              disabled={applicationState.status === 'loading'}
              data-testid={`start-${Tool.PreSales?.toLocaleLowerCase().replace(' ', '-')}`}
              sx={{
                width: 250,
                mb: 6,
              }}
              endIcon={
                <img src={ArrowIcon} alt="" width={16} />
              }
            >
              {t('components.preSalesIntro.startPreSales')}
            </Button>
          )}
        </ProductContainer>
        <FeaturePanelContainer direction="row" justifyContent="space-evenly">
          {features.map((feature) => (
            <FeaturePanel
              key={feature.title}
              icon={feature.icon}
              title={t(feature.title)}
              description={t(feature.description)}
            />
          ))}
        </FeaturePanelContainer>
      </Stack>
    </Container>
  );
}

export default PreSalesIntro;
