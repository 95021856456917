import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ApplicantDto } from '../../../services/models/applicant-dto';
import { ProductDto } from '../../../services/models/product-dto';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ApplicationPage } from '../../../routes/pages';
import { ProductCode } from '../../../services/models/product-code';
import {
  HealthCareDetails,
  IncomeProtectionDetails,
  ProductDetailsProps,
  RealLifeDetails,
} from '../../product-details';
import { applicantSelected, selectAvailableApplication } from '../../../features/application-slice';
import { getProductDecision } from '../../../services/decision-helpers';
import { TotalPrice } from '../../../routes/application/components/price';
import { asClientDate } from '../../../utils/converters';
import { ReviewAttribute, defaultReviewAttributeProps } from '../review-attribute';
import ProductCardTitle from '../../product-card-title';

const productDetailsProps: Partial<ProductDetailsProps> = {
  direction: 'column',
  gap: 0.5,
  minWidth: 400,
  labelAndValueProps: defaultReviewAttributeProps,
};

function getProductDetails(product: ProductDto, decisionCoverAmount: number | undefined) {
  switch (product.code) {
    case ProductCode.INCOME_PROTECTION: return (
      <IncomeProtectionDetails
        product={product}
        decisionCoverAmount={decisionCoverAmount}
        {...productDetailsProps}
      />
    );
    case ProductCode.REAL_LIFE: return (
      <RealLifeDetails
        product={product}
        decisionCoverAmount={decisionCoverAmount}
        {...productDetailsProps}
      />
    );
    case ProductCode.HEALTH_CARE: return <HealthCareDetails product={product} {...productDetailsProps} />;
    default: return null;
  }
}

export interface ProductReviewProps {
  applicationId: string;
  applicant: ApplicantDto;
  product: ProductDto;
  readonly?: boolean;
}

function ProductReview({
  applicationId,
  applicant,
  product,
  readonly = false,
}: ProductReviewProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { quoteDecision } = useAppSelector(selectAvailableApplication);
  const productDecision = getProductDecision(quoteDecision, product.id);
  const handleAmend = () => {
    dispatch(applicantSelected(applicant.id));
    navigate(generatePath(ApplicationPage.path, { applicationId }));
  };
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h3' }}
        title={(
          <Stack direction="row" gap={4} alignItems="baseline">
            <ProductCardTitle
              product={product}
              applicant={applicant}
              sx={{ minWidth: 300 }}
              decoratorProps={{ offset: readonly ? -16 : undefined }}
            />
            {product.policyReference && (
              <Typography variant="body2">
                {t('components.productReview.policyReference', { policyReference: product.policyReference })}
              </Typography>
            )}
          </Stack>
        )}
        action={!readonly && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAmend}
            sx={{ marginLeft: 4, paddingY: 0.5 }}
          >
            {t('common.amend')}
          </Button>
        )}
      />
      <CardContent>
        <Stack direction="row" gap={8}>
          {getProductDetails(product, productDecision?.coverAmount)}
          {productDecision?.premiums && (
            <TotalPrice
              premium={productDecision?.premiums}
              priceProps={{ size: 'medium' }}
              frequency={t('common.pricePeriods.perMonth')}
              sx={{ alignSelf: 'flex-end' }}
            />
          )}
        </Stack>
      </CardContent>
      <Divider />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">{t('components.productReview.startDate')}</Typography>
        <ReviewAttribute
          label={t('components.productReview.policyStart')}
          value={asClientDate(product.startDate)}
        />
      </CardContent>
    </Card>
  );
}

export default ProductReview;
