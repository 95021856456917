import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import DownloadIcon from '../../assets/icons/download-icon.svg';
import useBusyState from '../../hooks/use-busy-state';
import documentApi from '../../services/document-api';
import { toastError } from '../toast';
import analytics from '../../services/analytics';

interface DownloadDocumentButtonProps extends LoadingButtonProps {
  generateDocument: () => Promise<any>;
  fileName: string;
  label: string;
  resourceName: string;
  errorMessage: string;
}

function DownloadDocumentButton({
  generateDocument,
  fileName,
  label,
  resourceName,
  errorMessage,
  ...props
}: DownloadDocumentButtonProps) {
  const [loading, withLoadingState] = useBusyState();

  const handleClick = withLoadingState(async () => {
    try {
      await documentApi.downloadDocument(generateDocument(), fileName);
      analytics.trackResourceDownload(resourceName);
    } catch (e) {
      toastError(errorMessage);
    }
  });

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={handleClick}
      endIcon={<img src={DownloadIcon} width={16} alt={label} />}
      loading={loading}
      loadingPosition="end"
      {...props}
    >
      {label}
    </LoadingButton>
  );
}

export default DownloadDocumentButton;
