import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormContainer, FormHelpText } from '../../../../components/form';

export const FormContainerStyled = styled(FormContainer)(({ theme }) => ({
  '& label': {
    marginBottom: theme.spacing(1),
  },
  '& .MuiFormGroup-root': {
    paddingTop: theme.spacing(1),
  },
  '& .MuiFormControl-root': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    minWidth: 100,
    maxWidth: 180,
    '&:first-of-type': {
      maxWidth: 'none',
      display: 'inline-block',
      '& > .MuiBox-root': {
        '& .MuiFormControlLabel-root:last-of-type': {
          marginRight: 0,
        },
      },
    },
    '&:nth-of-type(2)': {
      maxWidth: 140,
    },
    '&:nth-of-type(3)': {
      width: 100,
    },
    '&:nth-of-type(4)': {
      width: 300,
      maxWidth: 300,
    },
  },
  '& .MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label': {
    fontFamily: theme.typography.h5.fontFamily,
  },
  [theme.breakpoints.down('lg')]: {
    '& button': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const ConfirmStatements = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  '& > ul': {
    margin: 0,
    '& > li': {
      ...theme.typography.body2,
      marginBottom: theme.spacing(1),
    },
  },
}));

export const PaymentHelpText = styled(FormHelpText)(({ theme }) => ({
  maxWidth: 325,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default FormContainerStyled;
