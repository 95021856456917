import { saveAs } from 'file-saver';
import { instance } from './http';

/**
 * Download the generation of a Presale document for the application.
 */
async function downloadPresaleDocument(applicationId: string, onboarding: boolean): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/preSale`, { params: { onboarding }, responseType: 'blob' });
}

/**
 * Download the generation of a Q&A illustration document for the application.
 */
async function downloadQuestionsAndAsnwersDocument(applicationId: string, applicantId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/applicants/${applicantId}/documents/questionAnswerIllustration`, { responseType: 'blob' });
}

/**
 * Download the generation of a quote illustration document for the application.
 */
async function downloadQuoteIllustrationDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/quoteIllustration`, { responseType: 'blob' });
}

/**
 * Download the generation of an acceptance document for the application.
 */
async function downloadAcceptanceDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/acceptanceTerms`, { responseType: 'blob' });
}

/**
 * Download the generation of a direct debit for the application.
 */
async function downloadDirectDebitDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/directDebit`, { responseType: 'blob' });
}

/**
 * Download the generation of a policy schedule for the application.
 */
async function downloadPolicyScheduleDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/policySchedule`, { responseType: 'blob' });
}

async function downloadDocument(downloadRequest: Promise<any>, fileName: string) {
  try {
    const response = await downloadRequest;
    saveAs(response.data, fileName);
  } catch {
    throw new Error('Document download failed.');
  }
}

const documentApi = {
  downloadDocument,
  downloadPresaleDocument,
  downloadQuestionsAndAsnwersDocument,
  downloadQuoteIllustrationDocument,
  downloadAcceptanceDocument,
  downloadDirectDebitDocument,
  downloadPolicyScheduleDocument,
};

export default documentApi;
