import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import DownloadDocumentButton from '../../../../components/download/download-document-button';
import { getApplicantName } from '../../../../services/application-helpers';
import documentApi from '../../../../services/document-api';
import { ApplicationDocumentContainer } from './application-documents.styles';
import { QuoteDecision } from '../../../../services/models/quote-decision';

export interface ApplicationDocumentsProps {
  applicationId: string;
  applicants: ApplicantDto[];
  quoteDecision?: QuoteDecision | null;
}

function ApplicationDocuments({ applicationId, applicants, quoteDecision = null }: ApplicationDocumentsProps) {
  const { t } = useTranslation();
  const generateQuoteDocument = () => documentApi.downloadQuoteIllustrationDocument(applicationId);
  const generateAcceptanceDocument = () => documentApi.downloadAcceptanceDocument(applicationId);
  const generatePolicyScheduleDocument = () => documentApi.downloadPolicyScheduleDocument(applicationId);
  const generateApplicationSummaryDocument = (applicantId: string) => documentApi.downloadQuestionsAndAsnwersDocument(applicationId, applicantId);
  const generateDirectDebitDocument = () => documentApi.downloadDirectDebitDocument(applicationId);

  return (
    <ApplicationDocumentContainer gap={2} direction="row" alignItems="flex-start" flexWrap="wrap">
      {applicants.map((applicant) => (
        <DownloadDocumentButton
          key={applicant.id}
          label={`${t('components.applicationDocuments.applicationSummary.label')} - ${getApplicantName(applicant)}`}
          fileName={`Application summary - ${getApplicantName(applicant)} - ${applicationId}.pdf`}
          resourceName="Application summary"
          errorMessage={t('errors.documentDownload')}
          generateDocument={() => generateApplicationSummaryDocument(applicant.id)}
          variant="text"
          color="secondary"
        />
      ))}
      {quoteDecision && (
        <DownloadDocumentButton
          label={t('components.applicationDocuments.quoteIllustration.label')}
          fileName={`Quote illustration - ${applicationId}.pdf`}
          resourceName="Quote illustration"
          errorMessage={t('errors.documentDownload')}
          generateDocument={generateQuoteDocument}
          variant="text"
          color="secondary"
        />
      )}
      {quoteDecision && (
        <DownloadDocumentButton
          label={t('components.applicationDocuments.acceptanceOffer.label')}
          fileName={`Acceptance offer - ${applicationId}.pdf`}
          resourceName="Acceptance offer"
          errorMessage={t('errors.documentDownload')}
          generateDocument={generateAcceptanceDocument}
          variant="text"
          color="secondary"
        />
      )}
      <DownloadDocumentButton
        label={t('components.applicationDocuments.policySchedule.label')}
        fileName={`Policy schedule - ${applicationId}.pdf`}
        resourceName="Policy schedule"
        errorMessage={t('errors.documentDownload')}
        generateDocument={generatePolicyScheduleDocument}
        variant="text"
        color="secondary"
      />
      <DownloadDocumentButton
        label={t('components.applicationDocuments.directDebit.label')}
        fileName={`Direct debit - ${applicationId}.pdf`}
        resourceName="Direct debit"
        errorMessage={t('errors.documentDownload')}
        generateDocument={generateDirectDebitDocument}
        variant="text"
        color="secondary"
      />
    </ApplicationDocumentContainer>
  );
}

export default ApplicationDocuments;
