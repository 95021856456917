import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/global/exeter-logo.svg';
import { getUserPreferenceAllowCookies, setUserPreferenceAllowCookies } from '../../services/profile-helpers';
import analyticsService from '../../services/analytics';

function CookieNotification() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (acceptCookies: boolean) => {
    setUserPreferenceAllowCookies(acceptCookies);
    if (acceptCookies) {
      analyticsService.initGA();
    }
    setOpen(false);
  };

  useEffect(() => {
    if (getUserPreferenceAllowCookies() === null) {
      setOpen(true);
    }
  }, []);

  return (
    <Dialog
      open={open}
      data-testid="cookie-dialog"
    >
      <DialogTitle>
        <img src={Logo} alt={t('components.cookieNotification.title')!} />
      </DialogTitle>
      <DialogContent>
        {t('components.cookieNotification.description')}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="contained" color="secondary" onClick={() => handleClick(false)}>
          {t('components.cookieNotification.reject')}
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleClick(true)}>
          {t('components.cookieNotification.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CookieNotification;
