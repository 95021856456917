import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { AddressResultDto } from '../../services/bff/models/address-result-dto';
import applicationApi from '../../services/application-api';

interface PostcodeSearchProps {
  defaultValue?: string
  onChange: (address: AddressResultDto | null) => void;
}

function PostcodeSearch({
  defaultValue = '',
  onChange,
}: PostcodeSearchProps) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<AddressResultDto[] | null>();
  const [inputValue, setInputValue] = React.useState(defaultValue);
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchOptions = async () => {
    if (inputValue.length <= 0) {
      setOptions([]);
      return;
    }
    setBusy(true);
    try {
      const fetchedOptions = await applicationApi.searchAddress(inputValue);
      setOptions(fetchedOptions?.items ?? []);
    } catch (e) {
      setError(e);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const closeError = () => setError(null);

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (options) {
      onChange(options.find((option) => option.id === value) ?? null);
    }
  };

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={2} alignItems="flex-end">
        <TextField value={inputValue} onChange={handleOnInputChange} placeholder="Postcode" inputProps={{ autoComplete: 'new-password' }} />
        <Button color="secondary" variant="outlined" onClick={fetchOptions} disabled={busy || inputValue === ''}>{t('common.search')}</Button>
      </Stack>
      {options && (
        <Select
          onChange={handleChange}
          placeholder={t('components.postcodeSearch.select')!}
          displayEmpty
          size="small"
          value=""
        >
          <MenuItem disabled value="">{t('components.postcodeSearch.select')!}</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>{`${option.text}, ${option.description}`}</MenuItem>
          ))}
          {options.length === 0 && <MenuItem value="">{t('components.postcodeSearch.notFound')!}</MenuItem>}
        </Select>
      )}
      <Snackbar
        open={!!error}
        onClose={closeError}
      >
        <Alert onClose={closeError} severity="error" sx={{ width: '100%' }}>
          {t('components.typeahead.optionsError')}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default PostcodeSearch;
