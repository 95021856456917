import React, {
  FormEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Box,
  Divider,
  FormLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DrawerCard,
  DrawerCardContent,
  DrawerCardHeader,
  DrawerCardActions,
} from '../../../../components/drawer-card';
import {
  DateField,
  PostcodeField as FormPostcodeField,
  RadioOptionsField,
} from '../../../../components/form/fields';
import { SmokerStatus } from '../../../../services/models/smoker-status';
import { Gender } from '../../../../services/models/gender';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { FormContainer, FormFieldContainer } from '../../../../components/form';
import { Option } from '../../../../components/types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectEnquiryFor,
  patchApplicant,
  selectAddressFor,
  patchApplicantContactAddressField,
  selectAvailableApplication,
} from '../../../../features/application-slice';
import BirthdateOrAge from './birthdate-or-age';
import {
  ApplicantStatusSectionName,
  basicClientDetailsValidationSchema,
  fullClientDetailsValidationSchema,
  getApplicantSectionStatus,
  getApplicantStatus,
} from '../../../../services/application-helpers';
import NameFields from './name-fields';
import {
  birthDateQuestionName,
  genderQuestionName,
  getAdditionalAnswers,
  getClientDetailsEnquiryLine,
  getQuestionByName,
  smokingQuestionName,
} from '../../../../services/enquiry-helpers';
import {
  EnquiryLine,
  getQuestionHelpText,
  getQuestionLabel,
} from '../../../../components/form/enquiry';
import {
  getFormValuesFor,
  getValidationSchemaForQuestions,
  handleError,
  handlePostcodeError,
} from '../../../../components/form/form.utils';
import { Enquiry } from '../../../../services/models/enquiry';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import useOptionLookup from '../../../../hooks/use-option-lookup';
import useSendAnswerAndUpdateForm from '../../../../hooks/use-send-answer-and-update-form';
import { HintCardContent } from '../../../../components/card-hint';
import applicationApi from '../../../../services/application-api';
import useFocusScroll from '../../../../hooks/use-focus-scroll';
import EnquiryFormProvider from '../../../../components/form/enquiry/enquiry-form-provider';
import { StatusDto } from '../../../../services/models/status-dto';
import { SectionStatus } from '../../../../services/models/section-status';
import { PostcodeFieldProps } from '../../../../components/form/fields/postcode-field';

export interface ClientDetailsFormProps {
  onSubmit: () => void
  applicationId: string;
  applicant: ApplicantDto;
  fullDetails?: boolean;
}

const smokerStatusOptions: Option[] = [
  { value: SmokerStatus.Smoker, label: 'yes' },
  { value: SmokerStatus.NonSmoker, label: 'no' },
];

function getEnquiryFormData(enquiry: Enquiry, fullDetails?: boolean) {
  const enquiryLine = fullDetails ? getClientDetailsEnquiryLine(enquiry) : null;
  return getFormValuesFor(enquiryLine?.questions || [], getAdditionalAnswers(enquiry));
}

interface FieldProps {
  enquiry: Enquiry;
  onChangeCommitted: (name: string, value: unknown) => Promise<void>;
}

function GenderField({ enquiry, onChangeCommitted }: FieldProps) {
  const question = getQuestionByName(enquiry, genderQuestionName);
  return (
    <FormFieldContainer helpText={getQuestionHelpText(question)}>
      <RadioOptionsField
        label={getQuestionLabel(question)}
        name="gender"
        options={Object.values(Gender)}
        labelTranslationBasePath="common.gender"
        onChangeCommitted={onChangeCommitted}
      />
    </FormFieldContainer>
  );
}

function AgeField({ fullDetails, enquiry, onChangeCommitted }: FieldProps & { fullDetails: boolean; }) {
  const question = getQuestionByName(enquiry, birthDateQuestionName);
  return fullDetails
    ? (
      <FormFieldContainer helpText={getQuestionHelpText(question)}>
        <DateField
          name="dateOfBirth"
          label={getQuestionLabel(question)}
          disableFuture
          onChangeCommitted={onChangeCommitted}
        />
      </FormFieldContainer>
    )
    : (
      <FormFieldContainer helpText={getQuestionHelpText(question)}>
        <Box>
          <FormLabel sx={{ display: 'block' }}>{getQuestionLabel(question)}</FormLabel>
          <BirthdateOrAge onChangeCommitted={onChangeCommitted} />
        </Box>
        <Divider />
      </FormFieldContainer>
    );
}

function PostcodeField(props: Partial<PostcodeFieldProps>) {
  const { t } = useTranslation();
  return (
    <FormFieldContainer>
      <FormPostcodeField
        label={t('components.clientDetailsForm.postcode')}
        name="postcode"
        sx={{ maxWidth: 150 }}
        {...props}
      />
    </FormFieldContainer>
  );
}

function SmokingField({ enquiry, onChangeCommitted }: FieldProps) {
  const question = getQuestionByName(enquiry, smokingQuestionName);
  return (
    <FormFieldContainer helpText={getQuestionHelpText(question)}>
      <RadioOptionsField
        label={getQuestionLabel(question)}
        name="smokerStatus"
        options={smokerStatusOptions}
        labelTranslationBasePath="common"
        onChangeCommitted={onChangeCommitted}
      />
    </FormFieldContainer>
  );
}

function isContantDetailsSectionComplete(applicationStatus: StatusDto, applicantId: string): boolean {
  const applicantStatus = getApplicantStatus(applicationStatus, applicantId);
  const contactDetailsStatus = getApplicantSectionStatus(ApplicantStatusSectionName.ContactInformation, applicantStatus);
  return contactDetailsStatus === SectionStatus.Complete;
}

function ClientDetailsForm({
  applicationId,
  applicant,
  onSubmit,
  fullDetails = false,
}: ClientDetailsFormProps) {
  const queue = useRef(new SequentialTaskQueue()).current;
  const cardRef = useRef<HTMLDivElement>(null);
  useFocusScroll(cardRef, 100);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const enquiry = useAppSelector(selectEnquiryFor(applicant.id));
  const enquiryLine = fullDetails ? getClientDetailsEnquiryLine(enquiry) : null;
  const questions = enquiryLine?.questions || [];
  const { applicationStatus } = useAppSelector(selectAvailableApplication);
  const contactDetailsComplete = isContantDetailsSectionComplete(applicationStatus, applicant.id);
  const postcode = useAppSelector(selectAddressFor(applicant.id))?.postcode || null;
  const resolver = fullDetails
    ? yupResolver(fullClientDetailsValidationSchema.concat(getValidationSchemaForQuestions(questions)))
    : yupResolver(basicClientDetailsValidationSchema) as any;
  const formMethods = useForm({
    defaultValues: { ...applicant, ...getFormValuesFor(questions, getAdditionalAnswers(enquiry)), postcode },
    resolver,
    criteriaMode: 'all',
    mode: 'onBlur',
  });
  const {
    formState: { isValid },
    resetField,
    setValue,
    clearErrors,
    setError,
  } = formMethods;
  const { t } = useTranslation();
  const search = useOptionLookup(applicant.id);
  const getUpdatedEnquiryAnswers = useCallback((updatedEnquiry: Enquiry) => getEnquiryFormData(updatedEnquiry, fullDetails), [fullDetails]);
  const sendAnswer = useSendAnswerAndUpdateForm(applicant.id, formMethods, getUpdatedEnquiryAnswers, queue);

  const updateField = queue.sequentialize(async (name: string, value: unknown) => {
    const key = name as keyof ApplicantDto;
    try {
      const newValue = await dispatch(patchApplicant(applicationId, applicant.id!, name, value));
      resetField(key, { defaultValue: newValue, keepTouched: true });
      setValue(key, newValue);
    } catch (e) {
      handleError(e, (message) => {
        clearErrors(key);
        setError(key, { message });
      });
    }
  });

  const handlePostcodeChange = queue.sequentialize(async (name: string, value: string) => {
    const key = name as 'postcode';
    if (!await formMethods.trigger(key)) {
      return;
    }

    try {
      await applicationApi.lookupAddress(value.toUpperCase());
    } catch (e) {
      handlePostcodeError(e, (message) => {
        clearErrors(key);
        setError(key, { message, type: 'focus' });
      });
      return;
    }

    try {
      const newValue = await dispatch(patchApplicantContactAddressField(applicationId, applicant.id!, name, value)) || null;
      resetField(key, { defaultValue: newValue, keepTouched: true });
      setValue(key, newValue);
    } catch (e) {
      handleError(e, (message) => {
        clearErrors(key);
        setError(key, { message });
      });
    }
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await queue.waitForPendingTasks();
      await formMethods.handleSubmit(() => onSubmit())(event);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <EnquiryFormProvider enquiry={enquiry}>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit} autoComplete="off">
          <DrawerCard ref={cardRef}>
            <DrawerCardHeader
              title={t('components.clientDetailsForm.title')}
              subheader={t('components.clientDetailsForm.description')}
            />
            {!fullDetails && (
              <HintCardContent>
                {t('components.clientDetailsForm.residencyWarning')}
              </HintCardContent>
            )}
            <DrawerCardContent sx={{ paddingBottom: 0 }}>
              {fullDetails && (
                <>
                  <Box sx={{ marginBottom: 2 }}>
                    <FormLabel sx={{ display: 'block' }}>{t('components.clientDetailsForm.name')}</FormLabel>
                    <NameFields enquiry={enquiry} onChangeCommitted={updateField} />
                  </Box>
                  <Divider />
                </>
              )}

              <GenderField enquiry={enquiry} onChangeCommitted={updateField} />
              <AgeField fullDetails={fullDetails} enquiry={enquiry} onChangeCommitted={updateField} />
              {fullDetails && !contactDetailsComplete && (
                <PostcodeField onChangeCommitted={handlePostcodeChange} />
              )}
              <SmokingField enquiry={enquiry} onChangeCommitted={updateField} />

              {enquiryLine && (
                <EnquiryLine
                  enquiryLine={enquiryLine}
                  search={search}
                  onChangeCommitted={sendAnswer}
                  hideStatus
                />
              )}
            </DrawerCardContent>
            <DrawerCardActions>
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                disabled={!isValid || questions.some((q) => !q.isSatisfied)}
                loading={submitting}
              >
                {t('components.clientDetailsForm.confirmButtonLabel')}
              </LoadingButton>
            </DrawerCardActions>
          </DrawerCard>
        </FormContainer>
      </FormProvider>
    </EnquiryFormProvider>
  );
}

export default ClientDetailsForm;
