import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { UserDto } from './bff/models/user-dto';
import { PermissionsRoleDto } from './bff/models/permissions-role-dto';

const TOKEN_PROFILE_ATTR = 'the-exeter:profileId';

const APPLICATION_NAME = 'ONBOARDING';
const ASSIGN_APPLICATION_ROLES = ['NEW_BUSINESS_ADMIN', 'UNDERWRITING'];
const UPDATE_QUOTE_DATE_ROLES = ['NEW_BUSINESS_ADMIN'];

export async function getCurrentUserProfileId(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getAccessToken().payload[TOKEN_PROFILE_ATTR] ?? '';
}

export function getUserApplicationRoles(applicationName: string, user: UserDto): PermissionsRoleDto[] {
  return user.permissions?.find((permission) => permission.applicationName === applicationName)?.roles ?? [];
}

export function canUserAssignApplication(user: UserDto) {
  return getUserApplicationRoles(APPLICATION_NAME, user).some((roles) => roles.name && ASSIGN_APPLICATION_ROLES.includes(roles.name));
}

export function canUserUpdateQuoteDate(user: UserDto) {
  return getUserApplicationRoles(APPLICATION_NAME, user).some((roles) => roles.name && UPDATE_QUOTE_DATE_ROLES.includes(roles.name));
}

export const PSOB_SESSION_NAME = 'psob-user-preferences';

export type UserPreferencePageTableColumns = Record<string, string[]>;

export type UserPreferences = {
  allowCookies?: boolean,
};

export function getUserPreferences(): UserPreferences {
  if (typeof window === 'undefined') {
    return {};
  }
  const preferences = Cookies.get(PSOB_SESSION_NAME);
  return preferences ? JSON.parse(preferences) : {};
}

export function setUserPreferences(preferences: UserPreferences) {
  if (typeof window === 'undefined') {
    return;
  }
  Cookies.set(PSOB_SESSION_NAME, JSON.stringify(preferences));
}

export function getUserPreferenceAllowCookies(): boolean | null {
  const preferences = getUserPreferences();
  return preferences?.allowCookies ?? null;
}

export function setUserPreferenceAllowCookies(allowCookies: boolean) {
  const preferences = getUserPreferences();
  setUserPreferences({ ...preferences, allowCookies });
}

export function setForgetPassword() {
  sessionStorage.setItem('forgotPassword', 'true');
}

export function unsetForgetPassword() {
  sessionStorage.removeItem('forgotPassword');
}

export function isForgetPassword(): boolean {
  return !!sessionStorage.getItem('forgotPassword');
}

export default {};
