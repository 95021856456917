import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import useBusyState from '../../../../hooks/use-busy-state';
import { assignAgencySchema } from '../../../../services/agency-helpers';
import { SelectField, TypeaheadField } from '../../../../components/form/fields';
import agencyApi from '../../../../services/agency-api';
import { AgencyDto } from '../../../../services/bff/models/agency-dto';
import { Option } from '../../../../components/types';
import { toastError, toastSuccess } from '../../../../components/toast';
import { useAppDispatch } from '../../../../store/hooks';
import { updateApplicationOwner } from '../../../../features/application-slice';
import HeaderButton from '../application-layout/header-button';

interface AssignApplicationProps {
  applicationId: string
}

function AssignApplication({ applicationId }: AssignApplicationProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [busy, withBusyState] = useBusyState();
  const [loadingAdvisers, withAdviserBusyState] = useBusyState();
  const [firms, setFirms] = useState<AgencyDto[]>([]);
  const [advisers, setAdvisers] = useState<AgencyDto[]>([]);
  const dispatch = useAppDispatch();

  const formMethods = useForm({
    defaultValues: { firm: null, adviser: null },
    resolver: yupResolver(assignAgencySchema) as any,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const {
    formState: { isValid },
    reset,
    getValues,
    watch,
  } = formMethods;

  const activeFirm = watch('firm');

  const searchFirms = useCallback((async (stem: string): Promise<string[]> => {
    const response = await agencyApi.searchFirms(stem, { size: 100, from: 0 });
    setFirms(response);
    return response.map((item) => item?.name ?? '');
  }), [setFirms]);

  const searchAdvisers = useCallback(withAdviserBusyState(async (firmId: number) => {
    const response = await agencyApi.searchAdvisors(firmId, { size: 100, from: 0 });
    setAdvisers(response);
  }), [setAdvisers]);

  const handleConfirm = useCallback(withBusyState(async () => {
    const { adviser } = getValues();
    if (adviser) {
      try {
        dispatch(updateApplicationOwner(applicationId, adviser));
        toastSuccess('Application assigned successfully');
        setOpen(false);
        reset();
      } catch {
        toastError('Failed to assign application');
      }
    }
  }), [applicationId, getValues, setOpen]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    setAdvisers([]);
    const foundFirm = firms.find((firm) => firm?.name === activeFirm);
    if (foundFirm?.frn) {
      searchAdvisers(foundFirm.frn);
    }
  }, [activeFirm]);

  return (
    <>
      <HeaderButton
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
        icon={<PersonIcon />}
      >
        {t('components.applicationHeader.assignApplication')}
      </HeaderButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="assign-application-prompt"
        aria-describedby="assign-application-description"
      >
        <DialogTitle id="assign-application-prompt">
          <Typography variant="h3" component="span">{t('components.assignApplication.title')}</Typography>
        </DialogTitle>
        <DialogContent sx={{ minWidth: 450, maxWidth: 450 }}>
          <FormProvider {...formMethods}>
            <Stack gap={2}>
              <TypeaheadField
                name="firm"
                label={t('components.assignApplication.firm')}
                search={searchFirms}
                filterOptions={() => firms.map((firm) => firm?.name ?? '')}
                renderOption={(optionProps, option) => (
                  <li {...optionProps} key={option}>
                    {firms.find((firm) => firm.name === option)?.name}
                  </li>
                )}
              />
              <SelectField
                name="adviser"
                options={advisers.map((adviser) => ({ value: adviser.id, label: adviser.name }) as Option)}
                disabled={loadingAdvisers || !activeFirm || !advisers}
                label={t('components.assignApplication.adviser')}
                fullWidth
              />
            </Stack>
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{
          p: 2,
          pl: 3,
          pr: 3,
          justifyContent: 'space-between',
        }}
        >
          <Button onClick={handleClose} color="secondary">
            {t('common.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            autoFocus
            disabled={!isValid}
            loading={busy}
          >
            {t('common.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AssignApplication;
