import { PropsWithChildren, useCallback, useEffect } from 'react';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadApplication, selectApplicationState } from '../../features/application-slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSettingsState } from '../../features/settings-slice';
import {
  ActivePage,
  ApplicationPage,
  CompletePage,
  DashboardPage,
  ExpiredPage,
  SearchPage,
} from '../../routes/pages';
import { toastError } from '../toast';
import { ApplicationStatus } from '../../services/models/application-status';
import { isApplicationReadOnly } from '../../services/application-helpers';

function ApplicationLoader({ children }: PropsWithChildren) {
  const state = useAppSelector(selectApplicationState);
  const dispatch = useAppDispatch();
  const settingsState = useAppSelector(selectSettingsState);
  const { t } = useTranslation();

  const { applicationId } = useParams();
  const onCompletePage = useMatch(CompletePage.path);
  const onApplicationPage = useMatch(ApplicationPage.path);
  const navigate = useNavigate();

  const reload = useCallback(async (id: string) => {
    await dispatch(loadApplication(id));
  }, [dispatch]);

  useEffect(() => {
    if (applicationId && settingsState.status === 'available' && (state.status === 'none' || (state.status === 'available' && state.application.id !== applicationId))) {
      reload(applicationId);
      return;
    }
    if (state.status === 'available'
      && (state.applicationStatus.application.status === ApplicationStatus.PreSaleExpired
        || state.applicationStatus.application.status === ApplicationStatus.QuoteExpired)) {
      navigate(SearchPage.path);
      toastError(t('components.applicationLoader.expiredError'));
      return;
    }
    if (state.status === 'available'
      && state.applicationStatus.application.status === ApplicationStatus.Cancelled) {
      navigate(SearchPage.path);
      toastError(t('components.applicationLoader.cancelledError'));
      return;
    }
    if (state.status === 'available'
      && (state.applicationStatus.application.status === ApplicationStatus.Expired)) {
      navigate(generatePath(ExpiredPage.path, { applicationId: applicationId! }), { replace: true });
      return;
    }
    if (state.status === 'available'
      && (state.applicationStatus.application.status === ApplicationStatus.Active)
      && !onCompletePage) {
      navigate(generatePath(ActivePage.path, { applicationId: applicationId! }), { replace: true });
      return;
    }
    if (state.status === 'available' && isApplicationReadOnly(state.application) && !onApplicationPage) {
      navigate(generatePath(ApplicationPage.path, { applicationId: applicationId! }), { replace: true });
      return;
    }
    if (state.status === 'failed') {
      navigate(DashboardPage.path);
      toastError(state.error);
    }
  }, [applicationId, state, settingsState, reload]);

  if (state.status !== 'available' || settingsState.status !== 'available') {
    return null;
  }
  return children;
}

export default ApplicationLoader;
