import React from 'react';
import {
  Accordion,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { ProductDto } from '../../../../services/models/product-dto';
import { ProductCode } from '../../../../services/models/product-code';
import { HealthCareDetails, IncomeProtectionDetails, RealLifeDetails } from '../../../../components/product-details';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import { CommissionDetails } from '../../../../components/commission-details';
import ProductDecisionAndPremium from './product-decision-and-premium';
import { DecisionCardRow, DecissionDetailsAccordionSummary, ProductQuoteDecisionTableStyled } from './both-clients-products.styles';
import ProductCardTitle from '../../../../components/product-card-title';
import { reasonDecisionIsNotStandard } from '../../../../services/decision-helpers';

function getCardContent(product: ProductDto, decisionCoverAmount: number | undefined) {
  switch (product.code) {
    case ProductCode.INCOME_PROTECTION: return <IncomeProtectionDetails product={product} decisionCoverAmount={decisionCoverAmount} />;
    case ProductCode.REAL_LIFE: return (<RealLifeDetails product={product} decisionCoverAmount={decisionCoverAmount} />);
    case ProductCode.HEALTH_CARE: return <HealthCareDetails product={product} />;
    default: return null;
  }
}

export interface ProductDecisionCardProps {
  applicantId: string;
  product: ProductDto;
  decision?: ProductQuoteDecision | null;
}

function ProductDecisionCard({ applicantId, product, decision = null }: ProductDecisionCardProps) {
  const applicantDecision = decision?.applicantDecisions.find((ad) => ad.applicantId === applicantId);
  const showDecisionDetails = applicantDecision
    && (applicantDecision.evidences.length > 0
      || (applicantDecision.reasonDecisions.length > 0 && applicantDecision.reasonDecisions.some(reasonDecisionIsNotStandard)));
  const { t } = useTranslation();
  return (
    <Card elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant: 'h3', marginY: 0.5 }}
        title={<ProductCardTitle product={product} />}
      />
      <CardContent sx={{ pt: 2 }}>
        {getCardContent(product, decision?.coverAmount)}
      </CardContent>
      <DecisionCardRow>
        <ProductDecisionAndPremium decision={decision} />
      </DecisionCardRow>
      {showDecisionDetails && (
        <Accordion disableGutters>
          <DecissionDetailsAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${product.id}-decision-content`}
            id={`${product.id}-decision-header`}
          >
            <Typography variant="h4">{t('components.productDecisionCard.decisionDetails')}</Typography>
          </DecissionDetailsAccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <ProductQuoteDecisionTableStyled applicantId={applicantId} decision={decision} />
          </AccordionDetails>
        </Accordion>
      )}
      <CommissionDetails productId={product.id} commission={decision?.commission} />
    </Card>
  );
}

export default ProductDecisionCard;
