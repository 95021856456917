import React, { FormEvent, useRef, useState } from 'react';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Divider,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  FormProvider,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainer, FormFieldContainer, InputStatus } from '../../../../components/form';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActionsWithNavigation,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { ApplicationDto } from '../../../../services/models/application-dto';
import { ContactDetailsDto } from '../../../../services/models/contact-details-dto';
import { AddressDto } from '../../../../services/models/address-dto';
import {
  patchApplicantContactAddress,
  patchApplicantContactAddressField,
  patchContactDetails,
  patchContactDetailsField,
} from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';
import { PostcodeField, TextField } from '../../../../components/form/fields';
import { handleError, handlePostcodeError } from '../../../../components/form/form.utils';
import { RootState } from '../../../../store';
import { FormInfoBar } from './contact-details-form.styles';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import applicationApi from '../../../../services/application-api';
import {
  contactDetailsValidationSchema,
  formatLoqateAddress,
  getApplicantAddress,
  hasPostcodeOnly,
} from '../../../../services/application-helpers';
import { PostcodeSearch } from '../../../../components/address-search';
import useFocusScroll from '../../../../hooks/use-focus-scroll';
import { toastError } from '../../../../components/toast';

type FormData = Omit<ContactDetailsDto, 'addressId'> & Omit<AddressDto, 'id'>;

function getFromData(application: ApplicationDto, applicant: ApplicantDto): FormData {
  return {
    ...applicant.contactDetails,
    ...getApplicantAddress(application, applicant),
  };
}
function getAddress({
  address1,
  address2,
  address3,
  cityName,
  county,
  postcode,
}: FormData): AddressDto {
  return {
    address1,
    address2,
    address3,
    cityName,
    county,
    postcode,
  };
}
function getContactDetails({ emailAddress, phoneNumber }: FormData, addressId?: string | null): ContactDetailsDto {
  return { addressId, emailAddress, phoneNumber };
}

function useUpdateField<K extends keyof FormData>(
  queue: SequentialTaskQueue,
  {
    clearErrors,
    resetField,
    setError,
    setValue,
  }: UseFormReturn<FormData, any, undefined>,
  updateFunction: (key: K, value: unknown) => ThunkAction<Promise<FormData[K]>, RootState, undefined, AnyAction>,
) {
  const dispatch = useAppDispatch();
  return queue.sequentialize(async (name: string, value: unknown) => {
    const key = name as K;
    if (key === 'postcode' && typeof value === 'string' && value) {
      try {
        await applicationApi.lookupAddress(value.toUpperCase());
        clearErrors(key);
      } catch (e) {
        handlePostcodeError(e, (message) => {
          clearErrors(key);
          setError(key, { message, type: 'focus' });
        });
        return;
      }
    }

    try {
      const newValue = await dispatch(updateFunction(key, value)) as any;
      resetField(key, { defaultValue: newValue });
      setValue(key, newValue, { shouldTouch: true, shouldValidate: true });
    } catch (e) {
      handleError(e, (message) => {
        clearErrors(key);
        setError(key, { message });
      });
    }
  });
}

export interface ContactDetailsFormProps {
  application: ApplicationDto;
  applicant: ApplicantDto;
  onSubmit: () => unknown;
  onCancel: () => unknown;
  hideNavigation?: boolean;
}

function ContactDetailsForm({
  application,
  applicant,
  onSubmit,
  onCancel,
  hideNavigation = false,
}: ContactDetailsFormProps) {
  const dispatch = useAppDispatch();
  const queue = useRef(new SequentialTaskQueue()).current;
  const cardRef = useRef<HTMLDivElement>(null);
  useFocusScroll(cardRef, 100);
  const defaultValues = getFromData(application, applicant);
  const [submitting, setSubmitting] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState<boolean>(!!defaultValues.postcode && !hasPostcodeOnly(defaultValues));
  const { t } = useTranslation();
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(contactDetailsValidationSchema) as any,
    mode: 'onChange',
  });
  const { formState: { isValid }, setValue, getValues } = formMethods;
  const updateContactDetailsField = useUpdateField(
    queue,
    formMethods,
    (key, value) => patchContactDetailsField(application.id, applicant.id, key, value),
  );
  const updateAddressField = useUpdateField(
    queue,
    formMethods,
    (key, value) => patchApplicantContactAddressField(application.id, applicant.id, key, value),
  );

  const handleShowAddressSearch = () => {
    setShowAddressFields(false);
  };

  const handleAddressSelect = async (newAddress: AddressDto | null) => {
    try {
      if (newAddress && newAddress.id) {
        const foundAddress = await applicationApi.searchAddressById(newAddress.id);
        await dispatch(patchApplicantContactAddress(
          application.id,
          applicant.id,
          formatLoqateAddress(foundAddress),
        ));
        setValue('address1', foundAddress.line1, { shouldDirty: true, shouldValidate: true });
        setValue('address2', foundAddress.line2, { shouldDirty: true, shouldValidate: true });
        setValue('cityName', foundAddress.city, { shouldDirty: true, shouldValidate: true });
        setValue('postcode', foundAddress.postalCode, { shouldDirty: true, shouldValidate: true });
        setShowAddressFields(true);
      }
    } catch (e) {
      toastError(t('components.contactDetailsForm.addressSelectError'));
    }
  };

  const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      await queue.waitForPendingTasks();
      const formData = getValues();
      await dispatch(patchApplicantContactAddress(application.id, applicant.id, getAddress(formData)));
      await dispatch(patchContactDetails(application.id, applicant.id, getContactDetails(formData, applicant.contactDetails?.addressId)));
      await formMethods.handleSubmit(onSubmit)(event);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={handleSubmit} autoComplete="off">
        <DrawerCard ref={cardRef}>
          <DrawerCardHeader
            title={(
              <Breadcrumbs separator=">">
                <span>{t('components.contactDetailsForm.clientDetails')}</span>
                <span>{t('components.contactDetailsForm.title')}</span>
              </Breadcrumbs>
            )}
            action={<CancelDrawerButton onClick={onCancel} />}
          />
          <DrawerCardContent sx={{ paddingBottom: 0, paddingTop: !hideNavigation ? '98px' : 6, position: 'relative' }}>
            {!hideNavigation && (
              <FormInfoBar>
                <Typography variant="body1">{t('components.contactDetailsForm.instructions')}</Typography>
              </FormInfoBar>
            )}
            <FormLabel>{t('components.contactDetailsForm.address')}</FormLabel>
            <Stack gap={2} sx={{ mt: 2 }}>
              <ButtonGroup variant="outlined" color="secondary">
                <Button variant={!showAddressFields ? 'contained' : 'outlined'} onClick={handleShowAddressSearch}>Lookup address</Button>
                <Button variant={showAddressFields ? 'contained' : 'outlined'} onClick={() => setShowAddressFields(true)}>Enter manually</Button>
              </ButtonGroup>
              {!showAddressFields && (
                <>
                  <PostcodeSearch
                    defaultValue={getValues().postcode || defaultValues.postcode || ''}
                    onChange={handleAddressSelect}
                  />
                  <Divider />
                </>
              )}
              {showAddressFields && (
                <>
                  <FormFieldContainer hideDivider rowEnd={<InputStatus />}>
                    <TextField
                      sublabel={t('components.contactDetailsForm.address1')}
                      name="address1"
                      onChangeCommitted={updateAddressField}
                      InputProps={{
                        inputProps: {
                          maxLength: 60,
                          role: 'presentation',
                          autoComplete: 'off',
                        },
                      }}
                    />
                  </FormFieldContainer>
                  <FormFieldContainer hideDivider rowEnd={<InputStatus />}>
                    <TextField
                      sublabel={t('components.contactDetailsForm.address2')}
                      name="address2"
                      onChangeCommitted={updateAddressField}
                      InputProps={{
                        inputProps: { maxLength: 60, autoComplete: 'off', role: 'presentation' },
                      }}
                    />
                  </FormFieldContainer>
                  <FormFieldContainer rowEnd={<InputStatus />}>
                    <Stack direction="row" gap={3} className="MuiFormControl-root" alignItems="flex-start">
                      <TextField
                        sublabel={t('components.contactDetailsForm.cityName')}
                        name="cityName"
                        onChangeCommitted={updateAddressField}
                        FormControlProps={{ sx: { flex: '0 0 50%' } }}
                        InputProps={{
                          inputProps: { maxLength: 50, autoComplete: 'off', role: 'presentation' },
                        }}
                      />
                      <PostcodeField
                        sublabel={t('components.contactDetailsForm.postcode')}
                        name="postcode"
                        onChangeCommitted={updateAddressField}
                        FormControlProps={{ sx: { flex: '0 0 50%' } }}
                        inputProps={{
                          maxLength: 10, autoComplete: 'off', role: 'presentation',
                        }}
                      />
                    </Stack>
                  </FormFieldContainer>
                </>
              )}
            </Stack>
            <FormFieldContainer rowEnd={<InputStatus />}>
              <TextField
                label={t('components.contactDetailsForm.emailAddress')}
                name="emailAddress"
                type="email"
                onChangeCommitted={updateContactDetailsField}
                InputProps={{
                  inputProps: { maxLength: 40, autoComplete: 'off', role: 'presentation' },
                }}
              />
            </FormFieldContainer>
            <FormFieldContainer rowEnd={<InputStatus />} hideDivider>
              <TextField
                label={t('components.contactDetailsForm.phoneNumber')}
                name="phoneNumber"
                type="tel"
                onChangeCommitted={updateContactDetailsField}
                InputProps={{
                  inputProps: { maxLength: 20, autoComplete: 'off', role: 'presentation' },
                }}
              />
            </FormFieldContainer>
          </DrawerCardContent>
          <DrawerCardActionsWithNavigation
            onNext={handleSubmit}
            nextProps={{ disabled: !isValid || submitting }}
            hidePrevious={hideNavigation}
            hideNext={hideNavigation}
          >
            <LoadingButton
              variant={hideNavigation ? 'contained' : 'outlined'}
              color={hideNavigation ? 'primary' : 'secondary'}
              type="submit"
              disabled={!isValid}
              loading={submitting}
            >
              {t('components.contactDetailsForm.confirmButtonLabel')}
            </LoadingButton>
          </DrawerCardActionsWithNavigation>
        </DrawerCard>
      </FormContainer>
    </FormProvider>
  );
}

export default ContactDetailsForm;
