import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { FooterBar } from '../../../../components/layout/footer';
import { PolicySetupPage, CompletePage } from '../../../pages';
import { isApplicationActivationReady } from '../../../../services/application-helpers';
import applicationApi from '../../../../services/application-api';
import useBusyState from '../../../../hooks/use-busy-state';
import { StatusDto } from '../../../../services/models/status-dto';
import { toastError } from '../../../../components/toast';
import analytics from '../../../../services/analytics';

interface ReviewFooterProps {
  applicationId: string;
}

function ReviewFooter({ applicationId }: ReviewFooterProps) {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] = useState<StatusDto>();
  const [loading, withLoadingState] = useBusyState();
  const navigate = useNavigate();

  const init = async () => {
    try {
      setApplicationStatus(await applicationApi.getApplicationStatus(applicationId, true));
    } catch {
      toastError('Sorry, there was error getting the application status');
    }
  };

  const handleTriggerActivation = withLoadingState(async () => {
    try {
      await applicationApi.activateApplication(applicationId);
      analytics.trackActivateCover(applicationId);
      navigate(generatePath(CompletePage.path, { applicationId }));
    } catch {
      toastError('Sorry, there was problem activating this application');
    }
  });

  const handlePrevious = () => {
    navigate(generatePath(PolicySetupPage.path, { applicationId }));
  };

  const handleConfirmChanged = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setConfirm(checked);
  };

  const confirmDisabled = (applicationStatus && !isApplicationActivationReady(applicationStatus)) || !confirm;

  useEffect(() => {
    init();
  }, []);

  return (
    <FooterBar>
      <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
        <FormControlLabel
          label={t('components.reviewFooter.confirmCheckboxLabel')}
          value={confirm}
          control={<Checkbox id="confirm" onChange={handleConfirmChanged} />}
          sx={{ ml: 'auto' }}
        />
        <Stack gap={2} direction="row" sx={{ ml: 'auto' }}>
          <LoadingButton
            color="secondary"
            variant="outlined"
            onClick={handlePrevious}
            startIcon={<ArrowBackIcon />}
          >
            {t('common.previous')}
          </LoadingButton>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleTriggerActivation}
            endIcon={<ArrowForwardIcon />}
            disabled={confirmDisabled}
            loading={loading}
          >
            {t('common.confirm')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FooterBar>
  );
}

export default ReviewFooter;
