import React, {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ProductDto } from '../../../../services/models/product-dto';
import SectionHeader from '../../../../components/section-header/section-header';
import { AdditionalProductPanel, SelectProductPanel } from '../add-product';
import ProductPanel from '../product-panel/product-panel';
import { NewProductButton } from './products-panel.styles';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  deleteProduct,
  patchProduct,
  selectApplicantPremiumFor,
  selectApplication,
  selectApplicationStatus,
  selectProductsStatus,
  selectProductsStatusErrors,
  updateDecision,
} from '../../../../features/application-slice';
import {
  getErrorsApplicantId,
  getErrorsByProductId,
  getProductDefinitionByCode,
  getErrorsByProductDefinition,
  listProductErrors,
  getProductToolkit,
} from '../../../../services/product-helpers';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { hasInvalidProduct, isUMEApplication, isPreSalesAppliction } from '../../../../services/application-helpers';
import { AvailableProductDefinition } from '../../../../services/models/available-product-definition';
import { TotalPrice } from '../price';
import useBusyState from '../../../../hooks/use-busy-state';
import ToolkitPanel from '../toolkit-panel';
import { ProductCode } from '../../../../services/models/product-code';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import { FormErrors } from '../../../../components/form';
import ApplicationExpiry from '../application-expiry';

export interface ProductsPanelProps {
  applicant: ApplicantDto
  availableProducts: AvailableProductDefinition[]
  products: ProductDto[]
  applicationExpiry?: string | null
  onChange?: () => unknown;
  onConvertToFullApplication: () => void;
  readOnly?: boolean;
}

function ProductsPanel({
  applicant,
  availableProducts,
  products,
  applicationExpiry = undefined,
  onChange = undefined,
  onConvertToFullApplication,
  readOnly = false,
}: ProductsPanelProps, ref: Ref<unknown>) {
  const queue = useRef(new SequentialTaskQueue()).current;
  const [busy, withBusyState] = useBusyState(queue);
  const [selectingProduct, setSelectingProduct] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplication);
  const applicationStatus = useAppSelector(selectApplicationStatus);
  const premium = useAppSelector(selectApplicantPremiumFor(applicant.id));
  const isPreSales = isPreSalesAppliction(applicationStatus);
  const isUME = isUMEApplication(application.origin);
  const productsStatus = useAppSelector(selectProductsStatus);
  const productErrors = useAppSelector(selectProductsStatusErrors);
  const allProductsValid = productsStatus.status === 'valid' && !hasInvalidProduct(isPreSales, application);

  useEffect(() => {
    setSelectingProduct(false);
  }, [products.length]);

  const handleDeleteProduct = withBusyState(async (product: ProductDto) => {
    if (product.id) {
      await dispatch(deleteProduct(application.id, product));
    }
  });

  const handleEnableDecision = useCallback(withBusyState(async () => {
    await dispatch(updateDecision({ suppressErrors: true }));
  }), []);

  const handleProductChange = withBusyState(queue.sequentialize(async (productId: string, changes: Partial<ProductDto>) => {
    await dispatch(patchProduct(application.id, productId, changes));
  }));

  if (products.length <= 0) {
    return <SelectProductPanel products={availableProducts} onChange={onChange} />;
  }

  const applicantErrors = getErrorsApplicantId(applicant.id, productErrors);
  const ipToolkitResource = getProductToolkit(ProductCode.INCOME_PROTECTION, products, availableProducts);
  const realLifeToolkitResource = getProductToolkit(ProductCode.REAL_LIFE, products, availableProducts);

  return (
    <Box ref={ref}>
      <SectionHeader sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap',
      }}
      >
        <Stack direction="row" gap={2} alignItems="flex-end">
          {isPreSales ? t('components.productsPanel.preSalesTitle') : t('components.productsPanel.title')}
          <ApplicationExpiry expiryDate={applicationExpiry} status={applicationStatus} />
        </Stack>
        {premium && <TotalPrice premium={premium} sx={{ float: 'right' }} data-testid="premium" priceProps={{ size: 'large' }} />}
      </SectionHeader>
      <Stack gap={4} sx={{ maxWidth: '100%' }}>
        {applicantErrors.length > 0 && <FormErrors errors={listProductErrors(applicantErrors)} />}
        {products.map((product) => (
          <ProductPanel
            applicantId={applicant.id}
            product={product}
            key={product.id}
            onDelete={(!readOnly && !isUME) ? handleDeleteProduct : undefined}
            onEnableDecision={handleEnableDecision}
            allowDecision={!busy && allProductsValid}
            onProductChange={handleProductChange}
            productErrors={[
              ...getErrorsByProductId(product.id, productErrors),
              ...getErrorsByProductDefinition(getProductDefinitionByCode(product.code!, availableProducts), productErrors),
            ]}
            onConvertToFullApplication={onConvertToFullApplication}
            readOnly={readOnly}
          />
        ))}
        {selectingProduct && (
          <AdditionalProductPanel
            products={availableProducts}
            onCancel={() => setSelectingProduct(false)}
            onChange={onChange}
          />
        )}
        {!readOnly && !isUME && !selectingProduct && availableProducts.some((p) => p.available) && (
          <NewProductButton color="secondary" onClick={() => setSelectingProduct(true)}>
            <AddIcon />
            {t('components.productsPanel.addNewProduct')}
          </NewProductButton>
        )}
        {!isPreSales && ipToolkitResource && <ToolkitPanel toolkitResource={ipToolkitResource} productCode={ProductCode.INCOME_PROTECTION} />}
        {!isPreSales && realLifeToolkitResource && <ToolkitPanel toolkitResource={realLifeToolkitResource} productCode={ProductCode.REAL_LIFE} />}
      </Stack>
    </Box>
  );
}

export default forwardRef(ProductsPanel);
