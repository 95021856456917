import * as yup from 'yup';

export type JournalQueryParams = {
  from: number,
  size: number,
};

export interface AgencyQueryParams {
  from?: number,
  size?: number,
}

export const assignAgencySchema = yup.object({
  firm: yup.string().required('Firm is required'),
  adviser: yup.string().required('Adviser is required'),
});
