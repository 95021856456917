import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
} from '@mui/material';
import { TabContext } from '@mui/lab';
import {
  AccountCircleOutlined,
  Add,
  ExpandLess,
  ExpandMore,
  SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  AddClientButton,
  ClientTab,
  ClientTabList,
  ClientTabPanel,
  FloatingTogglePanelButton,
} from './client-details-bar.styles';
import ClientDetailsStatusPanel from './client-details-status-panel';
import { View, ProceedFunction, editApplicantView } from '../../../view';
import { Enquiry } from '../../../../services/models/enquiry';
import enquiryApi from '../../../../services/enquiry-api';
import { getApplicantName, isUMEApplication } from '../../../../services/application-helpers';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  addApplicant,
  selectActiveApplicant,
  selectAvailableApplication,
  applicantSelected,
  deleteApplicant,
} from '../../../../features/application-slice';
import useBusyState from '../../../../hooks/use-busy-state';

const addClientTab = 'Add';
const bothClientsTab = 'Both';

export interface ClientDetailsStatusBarProps {
  proceedToNextView?: ProceedFunction;
  readonly?: boolean;
}

function ClientDetailsStatusBar({
  proceedToNextView = undefined,
  readonly = false,
}: ClientDetailsStatusBarProps) {
  const { application, applicationStatus, activeApplicantIndex } = useAppSelector(selectAvailableApplication);
  const activeApplicant = useAppSelector(selectActiveApplicant);
  const activeApplicantStatus = activeApplicantIndex !== null ? applicationStatus.applicants?.[activeApplicantIndex] ?? null : null;
  const isUME = isUMEApplication(application.origin);
  const dispatch = useAppDispatch();
  const [busy, withBusyState] = useBusyState();
  const [open, setOpen] = useState(false);
  const [localeEnquiry, setLocalEnquiry] = useState<Enquiry>();
  const { t } = useTranslation();

  const handleGetLocalEnquiry = async () => {
    if (activeApplicant) {
      const e = await enquiryApi.getForApplicantWithLocale(application.id, activeApplicant.id, 'Summary');
      setLocalEnquiry(e);
    }
  };

  useEffect(() => {
    if (open) {
      handleGetLocalEnquiry();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [activeApplicantStatus]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue !== addClientTab) {
      const selectedId = newValue === bothClientsTab ? null : newValue;
      dispatch(applicantSelected(selectedId));
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEdit = (view: View) => {
    if (proceedToNextView) {
      proceedToNextView({ target: view, force: true });
    }
  };

  const handleAddApplicant = withBusyState(async () => {
    await dispatch(addApplicant(application.id));
    if (proceedToNextView) {
      proceedToNextView({ target: editApplicantView });
    }
  });

  const handleDeleteApplicant = withBusyState(async (applicantId: string) => {
    await dispatch(deleteApplicant(application.id, applicantId));
    setOpen(false);
  });

  return (
    <TabContext value={activeApplicant?.id ?? bothClientsTab}>
      <Box sx={{ width: '100%', minHeight: 36 }}>
        <ClientTabList onChange={handleTabChange} aria-label="Clients">
          {application.applicants.map((applicant) => (
            <ClientTab
              key={applicant.id}
              label={(
                <Stack direction="row" gap={1} alignItems="center">
                  <AccountCircleOutlined color="primary" titleAccess="" />
                  <span>
                    {getApplicantName(applicant)}
                  </span>
                </Stack>
              )}
              value={applicant.id}
            />
          ))}
          {application.applicants.length === 2 && (
            <ClientTab
              key={bothClientsTab}
              label={(
                <Stack direction="row" gap={1} alignItems="center">
                  <SupervisedUserCircleOutlined color="primary" titleAccess="" />
                  <span>
                    {t('components.clientDetailsBar.bothClients')}
                  </span>
                </Stack>
              )}
              value={bothClientsTab}
            />
          )}
          {!readonly && !isUME && application.applicants.length < 2 && (
            <AddClientButton
              onClick={handleAddApplicant}
              disabled={busy}
              label={(
                <Stack direction="row" alignItems="center" gap={0.5}>
                  {busy ? <CircularProgress size={16} /> : <Add color="primary" fontSize="small" />}
                  <span>
                    {t('components.clientDetailsBar.addClient')}
                  </span>
                </Stack>
              )}
              value={addClientTab}
            />
          )}
        </ClientTabList>
        {application.applicants.map((applicant, index) => {
          const status = applicationStatus.applicants![index];
          return (
            <ClientTabPanel
              key={applicant.id}
              value={applicant.id}
            >
              <FloatingTogglePanelButton
                endIcon={open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                onClick={handleClick}
              >
                {open ? t('components.clientDetailsBar.hideDetails') : t('components.clientDetailsBar.viewDetails')}
              </FloatingTogglePanelButton>
              <Collapse in={open} collapsedSize={36} timeout={200}>
                <ClientDetailsStatusPanel
                  applicant={applicant}
                  applicantStatus={status}
                  enquiry={localeEnquiry}
                  hideEdit={readonly}
                  onEdit={handleEdit}
                  hideDelete={readonly || isUME || application.applicants.length <= 1 || applicant.hasCompletedPreUWDeclaration === true}
                  onDelete={handleDeleteApplicant}
                />
              </Collapse>
            </ClientTabPanel>
          );
        })}
        <ClientTabPanel value={bothClientsTab} sx={{ height: 56 }} />
      </Box>
    </TabContext>
  );
}

export default ClientDetailsStatusBar;
