import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { ProductDto } from '../../../../services/models/product-dto';
import { useAppSelector } from '../../../../store/hooks';
import DocumentLink from '../../../../components/documents-panel/document-link';
import ProductCardTitle from '../../../../components/product-card-title';
import { selectAvailableSettings } from '../../../../features/settings-slice';
import { getApplicationDocuments } from '../../../../services/application-helpers';

interface ProductCompletedProps extends CardProps {
  applicant: ApplicantDto;
  product: ProductDto;
  policyRef?: string | null;
}

function ProductCompleted({
  applicant,
  product,
  policyRef = undefined,
  ...props
}: ProductCompletedProps) {
  const { t } = useTranslation();
  const settings = useAppSelector(selectAvailableSettings);

  return (
    <Card {...props}>
      <CardHeader
        titleTypographyProps={{ variant: 'h3' }}
        title={<ProductCardTitle product={product} applicant={applicant} />}
        sx={{ minHeight: 66 }}
      />
      <CardContent>
        {policyRef && (
          <Typography>
            <strong>{t('components.productCompleted.policyNumber')}</strong>
            <Box component="span" sx={{ display: 'inline-block', ml: 2 }} data-testid="policy-ref">{policyRef}</Box>
          </Typography>
        )}
      </CardContent>
      <Divider />
      <CardActions sx={{
        minHeight: 'auto',
        p: 2,
        pl: 3,
        pr: 3,
      }}
      >
        {getApplicationDocuments([product], settings.productDefinitions).map((document) => <DocumentLink key={document.location} document={document} />)}
      </CardActions>
    </Card>
  );
}

export default ProductCompleted;
