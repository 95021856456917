import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ReviewAttribute } from '../review-attribute';
import { ApplicationDto } from '../../../services/models/application-dto';

import { PolicySetupPage } from '../../../routes/pages';
import { nthNumber } from '../../../utils/converters';
import { booleanToYesNo } from '../../form/form.utils';

export interface BankDetailsReviewProps {
  application: ApplicationDto;
  readonly?: boolean;
}

function BankDetailsReview({
  application,
  readonly = false,
}: BankDetailsReviewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleAmend = () => {
    navigate(generatePath(PolicySetupPage.path, { applicationId: application.id }));
  };
  const { bankDetails } = application;
  const advised = booleanToYesNo(application.isAdvisedSale);
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h3' }}
        title={t('components.bankDetailsReview.header')}
        action={!readonly && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAmend}
            sx={{ marginLeft: 4, paddingY: 0.5 }}
          >
            {t('common.amend')}
          </Button>
        )}
      />
      <Stack direction="row" flexWrap="wrap" gap={(theme) => theme.mixins.mainHorizontalSpacing}>
        <CardContent sx={{ flex: '1 1 50%' }}>
          <Typography variant="body1" fontWeight="bold" marginBottom={0.5}>
            {t('components.bankDetailsReview.bankDetails')}
          </Typography>
          <ReviewAttribute label={t('components.bankDetailsReview.accountName')} value={bankDetails?.accountName} />
          <ReviewAttribute label={t('components.bankDetailsReview.accountCode')} value={bankDetails?.accountCode} />
          <ReviewAttribute label={t('components.bankDetailsReview.sortCode')} value={bankDetails?.sortCode} />
          <ReviewAttribute
            label={t('components.bankDetailsReview.collectionDay')}
            value={bankDetails?.collectionDay
              ? t('components.bankDetailsReview.collectionDayOption', { day: nthNumber(bankDetails?.collectionDay) })
              : ''}
          />
        </CardContent>
        <CardContent sx={{ flex: '1 1 50%' }}>
          <Typography variant="body1" fontWeight="bold" marginBottom={0.5}>
            {t('components.bankDetailsReview.advice')}
          </Typography>
          <ReviewAttribute
            label={t('components.bankDetailsReview.isAdvisedSale')}
            value={advised ? t(`common.${advised}`) : ''}
          />
        </CardContent>
      </Stack>
    </Card>
  );
}

export default BankDetailsReview;
