export const adviserCognito = {
  cognitoUserPoolId: 'eu-west-1_9VmrxLBLY',
  cognitoIdentityPoolId: 'eu-west-1:c61a5077-c6e4-4dfe-bf76-6eac670d9c71',
  cognitoAppClientId: '3v7scr011t0c1i0g7bl5b133sq',
  cognitoUserPoolDomain: 'theexeter-adviser-pre.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const collegueCognito = {
  cognitoUserPoolId: 'eu-west-1_VLcEFxtjF',
  cognitoIdentityPoolId: 'eu-west-1:5a34beb2-0f25-4802-8eb9-588564455994',
  cognitoAppClientId: '5itlsiev47fpdrn9pnijrkk88o',
  cognitoUserPoolDomain: 'theexeter-colleague-pre.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const registrationEnabled = false;
export const googleAnalyticsTag = 'G-PD0M2WX1H9';
export const googleGtag = 'GTM-TFDQPZT4';
export const inactivityTimeout = '30'

export default {};
