import React, {
  useState,
  ChangeEvent,
  useEffect,
  FocusEvent,
} from 'react';
import { useController } from 'react-hook-form';
import { InputBaseProps } from '@mui/material/InputBase';
import _ from 'lodash';
import InputBase from './input-base';
import { WithOnChangeCommitted } from '../../types';
import { asStringOrNull } from '../../../utils/converters';

type TextInputProps = Omit<InputBaseProps, 'name'> & {
  name: string;
  allowedPattern?: string
  validateChange?: (value: string) => boolean;
  transform?: (value: string) => string;
} & WithOnChangeCommitted<string>;

function TextInput({
  name,
  type = undefined,
  onChangeCommitted = undefined,
  validateChange = undefined,
  transform = undefined,
  ...otherProps
}: TextInputProps) {
  const {
    field,
    formState: { defaultValues },
  } = useController({ name });
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (field.value || field.value === 0) {
      setValue(field.value.toString());
    }
  }, [field.value]);
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!validateChange || validateChange(newValue)) {
      setValue(newValue);
      field.onChange(newValue as any);
    }
  };

  const handleOnBlur = async (event: FocusEvent<HTMLInputElement>) => {
    const newValue = transform ? transform(value) : value;
    field.onChange(newValue as any);
    field.onBlur();
    if (otherProps.onBlur) {
      otherProps.onBlur(event);
    }
    const oldValue = _.get(defaultValues, name);
    if (onChangeCommitted && asStringOrNull(oldValue) !== asStringOrNull(value)) {
      await onChangeCommitted(name, newValue as any);
    }
  };

  return (
    <InputBase
      id={name}
      {...otherProps}
      {...field}
      type={type}
      value={value}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      fullWidth
    />
  );
}

export default TextInput;
