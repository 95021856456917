import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Error404, ErrorCatch } from './error';
import {
  DashboardPage,
  ApplicationPage,
  PolicySetupPage,
  ReviewPage,
  InternalLoginPage,
  SearchPage,
  CompletePage,
  ExpiredPage,
  ActivePage,
  RegistrationPage,
  FirmRegistrationPage,
  ForgotPasswordPage,
} from './pages';
import Application from './application';
import { Layout } from '../components/layout';
import PortalTitle from './search/components/portal-title';
import PolicySetup from './policy-setup';
import Review from './review';
import ApplicationLoader from '../components/application-loader/application-loader';
import DashboardTitle from './search/components/dashboard-title';
import Search from './search';
import Complete from './complete';
import Home from './home';
import Expired from './expired';
import Active from './active';
import Registration from './registration';
import { AppAuth } from '../components/app-auth';
import { registrationEnabled } from '../exports';
import FirmRegistration from './firm-registration';
import ForgotPassword from './forgot-password';

function AppRoutes() {
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route path={DashboardPage.path}>
        <Route element={<AppAuth><Layout header={<DashboardTitle />}><Outlet /></Layout></AppAuth>}>
          <Route
            index
            element={(
              <Home />
            )}
            errorElement={<ErrorCatch />}
          />
          <Route
            path={SearchPage.path}
            element={<Search />}
            errorElement={<ErrorCatch />}
          />
        </Route>
        <Route element={<AppAuth><ApplicationLoader><Outlet /></ApplicationLoader></AppAuth>}>
          <Route
            path={ApplicationPage.path}
            element={<Application />}
            errorElement={<ErrorCatch />}
          />
          <Route
            path={PolicySetupPage.path}
            element={<PolicySetup />}
            errorElement={<ErrorCatch />}
          />
          <Route
            path={ReviewPage.path}
            element={<Review />}
            errorElement={<ErrorCatch />}
          />
          <Route
            path={CompletePage.path}
            element={<Complete />}
            errorElement={<ErrorCatch />}
          />
          <Route
            path={ActivePage.path}
            element={<Active />}
            errorElement={<ErrorCatch />}
          />
          <Route
            path={ExpiredPage.path}
            element={<Expired />}
            errorElement={<ErrorCatch />}
          />
        </Route>
        <Route element={<AppAuth><Outlet /></AppAuth>}>
          <Route path={InternalLoginPage.path} />
        </Route>
        {registrationEnabled && (
          <Route
            path={RegistrationPage.path}
            element={<Outlet />}
          >
            <Route
              index
              element={<Registration />}
              errorElement={<ErrorCatch />}
            />
            <Route
              path={FirmRegistrationPage.path}
              element={<FirmRegistration />}
              errorElement={<ErrorCatch />}
            />
          </Route>
        )}
        <Route
          path={ForgotPasswordPage.path}
          element={<ForgotPassword />}
        />
        <Route
          path="*"
          element={(
            <Layout header={<PortalTitle />}>
              <Error404 />
            </Layout>
          )}
        />
      </Route>,
    ),
  );

  return <RouterProvider router={routes} />;
}

export default AppRoutes;
