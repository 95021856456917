import { AgencyQueryParams, JournalQueryParams } from './agency-helpers';
import { AgencyDto } from './bff/models/agency-dto';
import { InitialCommissionDto } from './bff/models/initial-commission-dto';
import { AdvisorJournalSearchResultDto } from './bff/models/advisor-journal-search-result-dto';
import http from './http';
import { AdvisorDto } from './bff/models/advisor-dto';

/**
 * Get advisor details.
 */
async function getAdvisor(adviserId: string): Promise<AdvisorDto> {
  return http.get('/api/v1/bff/Advisor', { adviserId });
}

/**
 * Get commissions
 */
async function getCommissions(): Promise<InitialCommissionDto[]> {
  return http.get('/api/v1/bff/dashboard/commissions');
}

/**
 * Retrieve journal entries
 */
async function getJournalEntries(advisorId: string, params: JournalQueryParams): Promise<AdvisorJournalSearchResultDto> {
  return http.get(`/api/v1/bff/advisor/${advisorId}/journal`, params);
}

/**
 * Returns whether the firm has access to onboarding.
 */
async function getFirmOnboardingAccess(firmFrn: string): Promise<boolean> {
  try {
    await http.get(`/api/v1/bff/firms/${firmFrn}/onboarding`);
    return true;
  } catch (e) {
    return false;
  }
}

async function searchAdvisors(firmId: number, params: AgencyQueryParams): Promise<AgencyDto[]> {
  return http.get(`/api/v1/bff/Firms/${firmId}/Advisors`, params);
}

async function searchFirms(firmName: string, params: AgencyQueryParams): Promise<AgencyDto[]> {
  return http.get('/api/v1/bff/Firms/GetByName', { ...params, firmName });
}

export default {
  getAdvisor,
  getCommissions,
  getFirmOnboardingAccess,
  getJournalEntries,
  searchAdvisors,
  searchFirms,
};
