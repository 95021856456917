import React, {
  Fragment, PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Stack } from '@mui/material';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import {
  Container,
  ExclusionsContainer,
  TableContainerStyled,
  TableCellInner,
  TableRow,
  TotalRatingDecisionCardContainer,
} from './decision-table.styles';
import { Loading } from '../../../../services/models/loading';
import {
  formatEmLoading,
  formatIndicativePmLoading,
  formatPmLoading,
  hasAnyIndicativeDecision,
  hasAnyIndicativeExclusions,
  hasAnyIndicativeLoadings,
  hasAnyLoadings,
  hasAnyTotalRatingLoadings,
  hasEvidence,
  hasExclusions,
  hasIndicativeLoading,
  isImmediateDecision,
  preSalesDecisionHasExclusions,
  reasonDecisionHasNoInd,
} from '../../../../services/decision-helpers';
import { DecisionType } from '../../../../services/product-helpers';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { TextWithInfo } from '../../../../components/info';
import { TotalRatingDecisionCard } from '../decision-total';
import { ReasonDecisionIndicative } from '../../../../services/models/reason-decision-indicative';
import Exclusions from '../deferred-periods/exclusions';
import { Decision, ImmediateDecision } from '../../../../components/decision';
import { RangedPermanentLoading } from '../../../../services/models/ranged-permanent-loading';
import { RangedTemporaryLoading } from '../../../../services/models/ranged-temporary-loading';
import { ReasonDecisionAccordion } from '../deferred-periods/deferred-periods.styles';

function displayLoading(loading: RangedPermanentLoading | RangedTemporaryLoading) {
  if (loading.min !== loading.max) {
    return `${formatEmLoading(loading.min)} to ${formatEmLoading(loading.max)}`;
  }
  return formatEmLoading(loading.min);
}

function displayEmRating(rating: Loading | undefined) {
  if (rating?.permanent?.total) {
    return formatEmLoading(rating.permanent.total);
  }
  if (rating?.temporary?.total) {
    return formatEmLoading(rating.temporary.total);
  }
  return '';
}

function displayDuration(rating: Loading | undefined) {
  if (rating?.temporary?.duration) {
    return `for ${rating.temporary.duration} months`;
  }
  return '';
}

function displayPmRating(rating: Loading | undefined) {
  if (rating?.permanent?.total) {
    return formatPmLoading(rating.permanent.total);
  }
  if (rating?.temporary?.total) {
    return `${formatPmLoading(rating.temporary.total)} ${displayDuration(rating)}`.trim();
  }
  return '';
}

interface DisplayEmPmRatingProps {
  emRating: Loading | undefined,
  pmRating: Loading | undefined,
  decision: string | null | undefined,
}

function EmPmRating({
  emRating,
  pmRating,
  decision,
}: DisplayEmPmRatingProps) {
  const ratings: string[] = [];
  if (emRating) {
    ratings.push(displayEmRating(emRating));
  }
  if (pmRating) {
    ratings.push(displayPmRating(pmRating));
  }

  if (ratings.join().length <= 0 && decision === DecisionType.NON_STANDARD) {
    return formatEmLoading(0);
  }
  return ratings.map((rating) => (
    <Fragment key={rating}>
      {rating}
      <br />
    </Fragment>
  ));
}

function displayInicativeLoadings(indicative: ReasonDecisionIndicative | undefined): string[] {
  const { em, pm } = indicative!;
  const response = [];
  if (hasIndicativeLoading(em?.permanent)) {
    response.push(displayLoading(em?.permanent!));
  }
  if (hasIndicativeLoading(em?.temporary)) {
    response.push(`${formatIndicativePmLoading(em?.temporary!)} for ${em?.temporary?.duration} months`);
  }
  if (hasIndicativeLoading(pm?.permanent)) {
    response.push(formatIndicativePmLoading(pm?.permanent!));
  }
  if (hasIndicativeLoading(pm?.temporary)) {
    response.push(`${formatIndicativePmLoading(pm?.temporary!)} for ${pm?.temporary?.duration} months`);
  }
  return response;
}

function getRowSpan(reasonDecision: ReasonDecision, hideNonStandardRating: boolean = false) {
  return [
    hasAnyLoadings(reasonDecision)
    || (reasonDecision.decision === DecisionType.NON_STANDARD && !hideNonStandardRating)
    || reasonDecisionHasNoInd(reasonDecision),
    hasAnyIndicativeLoadings(reasonDecision),
    hasEvidence(reasonDecision),
    hasExclusions(reasonDecision),
    hasAnyIndicativeExclusions(reasonDecision),
    hasAnyIndicativeDecision(reasonDecision),
  ].filter((hasRow) => !!hasRow).length + 1;
}

interface DecisionTableRowProps extends PropsWithChildren {
  header: string
}

function DecisionTableRow({ header, children }: DecisionTableRowProps) {
  return (
    <TableRow>
      <TableCell>
        <TableCellInner>
          {header}
          <span>
            {children}
          </span>
        </TableCellInner>
      </TableCell>
    </TableRow>
  );
}

export interface DecisionTableProps {
  decision: PreSalesDecision
  productId: string
  hideNonStandardRating?: boolean
}

function DecisionTable({
  decision,
  productId,
  hideNonStandardRating = false,
}: DecisionTableProps) {
  const { t } = useTranslation();

  const hasNoInd = decision.reasonDecisions?.some(reasonDecisionHasNoInd);
  const hasAnyExclusions = preSalesDecisionHasExclusions(decision);

  return (
    <Container>
      <TableContainerStyled>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="280">{t('components.decisionTable.decisionHeader')}</TableCell>
              <TableCell component="td" data-testid="overall-decision">
                <Stack direction="row" gap={4}>
                  <Decision decision={decision.decision} />
                  {isImmediateDecision(decision.decision) && <ImmediateDecision />}
                </Stack>
              </TableCell>
            </TableRow>
            {hasAnyTotalRatingLoadings(decision) && (
              <TableRow>
                <TableCell width="280">{t('components.decisionTable.totalRating')}</TableCell>
                <TableCell component="td" align="right">
                  {!hasNoInd && (
                    <TotalRatingDecisionCardContainer>
                      <TotalRatingDecisionCard inline totalRating={decision.totalRating!} />
                    </TotalRatingDecisionCardContainer>
                  )}
                  {hasNoInd && (
                    <TextWithInfo
                      variant="body2"
                      fontWeight="bold"
                      info={t('components.decisionTable.unavailableTotalInfo')}
                    >
                      {t('components.decisionTable.unavailable')}
                    </TextWithInfo>
                  )}
                </TableCell>
              </TableRow>
            )}
            {hasAnyExclusions && (
              <TableRow>
                <TableCell>
                  {t('components.decisionTable.totalExclusions')}
                </TableCell>
                <TableCell component="td">
                  <Exclusions decision={decision} />
                </TableCell>
              </TableRow>
            )}
            {decision.consolidatedEvidences && decision.consolidatedEvidences.length > 0 && (
              <TableRow>
                <TableCell width="280">{t('components.decisionTable.evidenceRequired')}</TableCell>
                <TableCell component="td">
                  {decision.consolidatedEvidences.map((evidence) => evidence.text).join(', ')}
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
        <ReasonDecisionAccordion productId={productId}>
          <Table sx={{ top: -1 }}>
            <TableBody>
              {decision.reasonDecisions?.map((reasonDecision) => (
                <Fragment key={reasonDecision.reason}>
                  <TableRow>
                    <TableCell width="280" rowSpan={getRowSpan(reasonDecision, hideNonStandardRating)} component="th">{reasonDecision.reason}</TableCell>
                    <TableCell>
                      <TableCellInner>
                        {t('components.decisionTable.decision')}
                        <TextWithInfo
                          component="span"
                          variant="body2"
                          info={reasonDecision.decision === DecisionType.POSTPONE && reasonDecision.postpones && reasonDecision.postpones.map((postpone) => (
                            <Fragment key={postpone.code}>
                              {postpone.text}
                              <br />
                            </Fragment>
                          ))}
                        >
                          {t(`common.decision.${reasonDecision.decision}`)}
                        </TextWithInfo>
                      </TableCellInner>
                    </TableCell>
                  </TableRow>
                  {(hasAnyLoadings(reasonDecision)
                    || (reasonDecision.decision === DecisionType.NON_STANDARD && !hideNonStandardRating)
                    || reasonDecisionHasNoInd(reasonDecision))
                    && (
                      <DecisionTableRow header={t('components.decisionTable.rating')}>
                        {reasonDecisionHasNoInd(reasonDecision) ? (
                          <TextWithInfo
                            component="span"
                            variant="body2"
                            info={t('components.decisionTable.unavailableInfo')}
                          >
                            {t('components.decisionTable.unavailable')}
                          </TextWithInfo>
                        ) : (
                          <EmPmRating
                            emRating={reasonDecision?.em}
                            pmRating={reasonDecision.pm}
                            decision={reasonDecision.decision}
                          />
                        )}
                      </DecisionTableRow>
                    )}
                  {hasEvidence(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.evidence')}>
                      {reasonDecision.evidences!.map((evidence) => evidence.text).join(', ')}
                    </DecisionTableRow>
                  )}
                  {hasExclusions(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.exclusions')}>
                      <ExclusionsContainer>
                        {reasonDecision.exclusions!.map((exclusion) => (
                          <TextWithInfo key={exclusion.code} component="span" variant="body2" info={exclusion.text}>
                            {exclusion.shortText}
                            {exclusion.duration && ` for ${exclusion.duration} months`}
                          </TextWithInfo>
                        ))}
                      </ExclusionsContainer>
                    </DecisionTableRow>
                  )}
                  {hasAnyIndicativeExclusions(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.indicativeExclusion')}>
                      <ExclusionsContainer>
                        {reasonDecision.indicative?.exclusions!.map((exclusion) => (
                          <TextWithInfo key={exclusion.code} component="span" variant="body2" info={exclusion.text}>
                            {exclusion.shortText}
                            {exclusion.duration && ` for ${exclusion.duration} months`}
                          </TextWithInfo>
                        ))}
                      </ExclusionsContainer>
                    </DecisionTableRow>
                  )}
                  {hasAnyIndicativeLoadings(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.indicativeRating')}>
                      {displayInicativeLoadings(reasonDecision?.indicative).join(` ${t('common.and')} `)}
                    </DecisionTableRow>
                  )}
                  {hasAnyIndicativeDecision(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.indicativeDecision')}>
                      {t(`common.decision.${reasonDecision.indicative?.decision}`)}
                    </DecisionTableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </ReasonDecisionAccordion>
      </TableContainerStyled>
    </Container>
  );
}

export default DecisionTable;
