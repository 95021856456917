import React from 'react';
import { Question as QuestionType } from '../../../services/models/question';
import QuestionError from './question-error';
import QuestionField from './question-field';
import UnitQuestionError from './unit-question-error';
import FormFieldContainer from '../form-field-container';
import { WithOnChangeCommitted } from '../../types';
import {
  GroupedQuestion,
  heightUnits,
  isQuestionDisplayable,
  weightUnits,
} from '../../../services/enquiry-helpers';
import InputStatus from '../input-status';

interface QuestionValidationErrorProps {
  question: GroupedQuestion
}

function QuestionValidationError({ question }: QuestionValidationErrorProps) {
  if (question.validationErrors && (question.definition.tags?.includes('HEIGHT') || question.definition.tags?.includes('WEIGHT'))) {
    return <UnitQuestionError question={question} systemName={question.definition.tags?.includes('HEIGHT') ? heightUnits : weightUnits} />;
  }
  if (question.validationErrors) {
    return <QuestionError validationErrors={Object.values(question.validationErrors).flatMap((it) => it)} />;
  }
  if (question.groupQuestions && question.groupQuestions.some((groupQuestion) => groupQuestion.validationErrors)) {
    const errors = question.groupQuestions.reduce((acc, curr) => {
      if (curr.validationErrors) {
        acc.concat(Object.values(curr.validationErrors).flatMap((it) => it));
      }
      return acc;
    }, [] as string[]);
    return <QuestionError validationErrors={errors} />;
  }
  return null;
}

export function getQuestionLabel(question: QuestionType | null): JSX.Element | undefined {
  // eslint-disable-next-line react/no-danger
  return question?.definition?.text ? <span dangerouslySetInnerHTML={{ __html: question.definition?.text }} /> : undefined;
}

export function getQuestionHelpText(question: QuestionType | null): { __html: string; } | undefined {
  return question?.definition?.helpText ? { __html: question.definition?.helpText } : undefined;
}

export function getQuestionComplete(question: GroupedQuestion | null): boolean | null | undefined {
  if (question?.groupQuestions) {
    return question.groupQuestions.reduce((acc, curr) => {
      if (acc === true && !curr.isSatisfied) {
        return false;
      }
      return acc;
    }, true);
  }
  return question?.isSatisfied;
}

interface QuestionProps extends WithOnChangeCommitted<unknown> {
  question: GroupedQuestion;
  disabled?: boolean;
  search?: (name: string, searchValue: string) => string[] | Promise<string[]>;
  hideDivider?: boolean;
  hideHelpText?: boolean;
  hideStatus?: boolean;
}

function Question({
  question,
  search = undefined,
  disabled = false,
  onChangeCommitted = undefined,
  hideDivider = undefined,
  hideHelpText = undefined,
  hideStatus = undefined,
}: QuestionProps) {
  if (question && question.path && isQuestionDisplayable(question)) {
    return (
      <FormFieldContainer
        helpText={getQuestionHelpText(question)}
        hideHelpText={hideHelpText}
        hideDivider={hideDivider}
        rowEnd={!hideStatus && <InputStatus complete={getQuestionComplete(question)} data-testid={`status-${question.path}`} />}
        columnEnd={<QuestionValidationError question={question} />}
      >
        <QuestionField
          question={question}
          label={getQuestionLabel(question)}
          disabled={disabled}
          onChangeCommitted={onChangeCommitted}
          search={search}
        />
      </FormFieldContainer>
    );
  }
  return null;
}

export default Question;
