import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { RadioOptionsField, SelectField, TextField } from '../../../../components/form/fields';
import { ConfirmStatements, FormContainerStyled } from './bank-details.styles';
import { FormErrors } from '../../../../components/form';
import { validateDigitsOnly } from '../../../../components/form/form.utils';
import { Option } from '../../../../components/types';
import { CheckboxInput } from '../../../../components/form/inputs';
import { nthNumber } from '../../../../utils/converters';
import DirectDebitPanel from './direct-debit-panel';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { getApplicantName } from '../../../../services/application-helpers';

const collectionDays: number[] = range(1, 29);

export interface BankDetailsProps {
  onChangeCommitted: (name: string, value: unknown) => unknown;
  onValidate: () => void;
  busy: boolean;
  isFormValid: boolean;
  errors: string[];
  validated: boolean;
  applicants: ApplicantDto[];
  bankAccountOwner: string[] | null | undefined;
  onModifyValidatedAccount: () => void;
}

function BankDetails({
  onChangeCommitted,
  onValidate,
  busy,
  isFormValid,
  errors,
  validated,
  applicants,
  bankAccountOwner,
  onModifyValidatedAccount,
}: BankDetailsProps) {
  const { t } = useTranslation();
  const collectionDayOptions: Option[] = useMemo(() => (
    collectionDays.map((day) => ({
      value: day.toString(),
      label: t('components.bankDetails.collectionDayOption', { day: nthNumber(day) }) ?? '',
    }))
  ), []);

  const handleCollectionDayChange = (name: string, value: string) => (
    onChangeCommitted(name, value ? parseInt(value, 10) : null));

  const handleBankAccountOwnerChange = async (name: string, value: string) => {
    await onChangeCommitted(name, [value]);
    const accountApplicant = applicants.find((applicant) => applicant.id === value);
    await onChangeCommitted('accountName', accountApplicant ? getApplicantName(accountApplicant) : '');
  };

  useEffect(() => {
    if (applicants.length === 1 && (!bankAccountOwner || bankAccountOwner.length === 0)) {
      handleBankAccountOwnerChange('bankAccountOwner', applicants[0].id);
    }
  }, []);

  return (
    <FormContainerStyled>
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>Bank details</Typography>
      <Grid container>
        <Grid item sm={12} md={12} lg={7}>
          <Stack direction="column" gap={2} alignItems="flex-start">
            <FormErrors errors={errors} />
            <Stack direction="row" gap={2} alignItems="flex-start" flexWrap="wrap">
              <RadioOptionsField
                label={t('components.bankDetails.accountName')}
                name="bankAccountOwner"
                options={applicants.map((applicant) => ({ label: getApplicantName(applicant), value: applicant.id }))}
                sx={{
                  minWidth: 200,
                  mt: -0.5,
                }}
                onChangeCommitted={handleBankAccountOwnerChange}
                hideError
                disabled={validated}
              />
              <TextField
                name="accountCode"
                type="text"
                label={t('components.bankDetails.accountCode')}
                InputProps={{
                  inputProps: { maxLength: 8 },
                  sx: { maxWidth: 160 },
                  readOnly: validated,
                }}
                onChangeCommitted={onChangeCommitted}
                validateChange={validateDigitsOnly}
                hideError
              />
              <TextField
                name="sortCode"
                type="text"
                label={t('components.bankDetails.sortCode')}
                InputProps={{
                  inputProps: { maxLength: 6 },
                  sx: { maxWidth: 100, minWidth: 100 },
                  readOnly: validated,
                }}
                onChangeCommitted={onChangeCommitted}
                validateChange={validateDigitsOnly}
                hideError
              />
              <SelectField
                name="collectionDay"
                label={t('components.bankDetails.collectionDay')}
                options={collectionDayOptions}
                sx={{
                  minWidth: 210,
                }}
                onChangeCommitted={handleCollectionDayChange}
                readOnly={validated}
                hideError
              />
            </Stack>
            <Box>
              <CheckboxInput
                name="confirm"
                label={t('components.bankDetails.confirmation')}
                checkedValue
                uncheckedValue={false}
                disabled={validated}
              />
              <ConfirmStatements>
                <ul>
                  <li>{t('components.bankDetails.confirmationStatements.0')}</li>
                  <li>{t('components.bankDetails.confirmationStatements.1')}</li>
                  <li>{t('components.bankDetails.confirmationStatements.2')}</li>
                </ul>
              </ConfirmStatements>
            </Box>
            {!validated && (
              <Button
                variant="contained"
                color="primary"
                onClick={onValidate}
                disabled={busy || !isFormValid}
              >
                {t('common.validate')}
              </Button>
            )}
            {validated && (
              <Stack direction="row" gap={1} alignItems="center">
                <CheckCircleOutlineIcon color="success" />
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ marginRight: 3 }}
                >
                  {t('components.bankDetails.validated')}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onModifyValidatedAccount}
                  disabled={busy}
                >
                  {t('components.bankDetails.changeAccount')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <DirectDebitPanel />
        </Grid>
      </Grid>
    </FormContainerStyled>
  );
}

export default BankDetails;
