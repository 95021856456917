import _ from 'lodash';
import { Enquiry } from './models/enquiry';
import { EnquiryLine } from './models/enquiry-line';
import { Question } from './models/question';
import { QuestionAnswerDto } from './models/question-answer-dto';
import { Section } from './models/section';
import {
  kilogrammsToImperial,
  metersToImperial,
  stringToMoney,
  toCentimeters,
} from '../utils/converters';
import { ProgressStatus } from './models/progress-status';

export enum GroupTags {
  ALCOHOL = 'GROUP/ALCOHOL',
}

export const preSalesDisclosureQuestionName = 'PRESALE_DISCLOSURES_LIST';

/**
 * Toggle one value in a multi-answer question.
 */
export const toggleAnswer = (value: string, currentValues: Array<string>, exclusive: boolean = false): Array<string> => {
  if (!currentValues.includes(value)) {
    return exclusive ? [value] : [...currentValues, value];
  }
  return [...currentValues.filter((item: string) => item !== value)];
};

/**
 * Remover a value from a multi-answer question.
 */
export const removeAnswer = (value: string, currentValues: Array<string>): Array<string> => (
  [...currentValues.filter((item: string) => item !== value)]
);

/**
 * Get an array value for an answer.
 */
export const getMultipleAnswer = (value: Array<string> | string | null | undefined): Array<string> => {
  if (Array.isArray(value)) {
    return value;
  }
  if (value && !Array.isArray(value)) {
    return [value];
  }
  return [];
};

/**
 * Get a single value for an answer.
 */
export const getSingleAnswer = (value: Array<string> | string | null | undefined): string | null => {
  if (Array.isArray(value) && value.length === 1) {
    return value[0];
  }
  if (typeof value === 'string') {
    return value;
  }
  return null;
};

export function areEqualAnswers(answer1: Array<string> | string | null | undefined, answer2: Array<string> | string | null | undefined): boolean {
  if (answer1 === answer2) {
    return true;
  }
  if (!answer1 || !answer2) {
    return false;
  }
  if (Array.isArray(answer1) && Array.isArray(answer2)) {
    return answer1.length === answer2.length && answer1.every((it) => answer2.includes(it));
  }
  if (typeof answer1 === 'string' && typeof answer2 === 'string') {
    return answer1 === answer2;
  }
  return false;
}

export const nonDisplayableTag = 'DO_NOT_DISPLAY';

export function isQuestionDisplayable(question: Question): boolean {
  return !question.definition?.tags?.includes(nonDisplayableTag);
}

export const getQuestionAnswer = (question: Question) => (
  question.definition.isMultiValued ? getMultipleAnswer(question.answers) : getSingleAnswer(question.answers)
);

function hasAnyAnswersOf(question: Question, answers: string[]) {
  return question.answers?.some((it) => answers.includes(it));
}

export function getEnquiryLineByPath(enquiry: Enquiry, path: string): EnquiryLine | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const section of enquiry?.sections || []) {
    const enquiryLine = section.enquiryLines?.find((e) => e.path === path);
    if (enquiryLine) {
      return enquiryLine;
    }
  }
  return null;
}

export const presalesDisclosuresPath = 'PRESALE_DISCLOSURES';

const clientDetailsTag = 'CLIENT_DETAILS';

export function getClientDetailsEnquiryLine(enquiry: Enquiry | null | undefined): EnquiryLine | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const section of enquiry?.sections || []) {
    const enquiryLine = section.enquiryLines?.find((e) => e.tags.includes(clientDetailsTag));
    if (enquiryLine) {
      return enquiryLine;
    }
  }
  return null;
}

export function unsatisfiedClientDetails(enquiry: Enquiry | null | undefined): EnquiryLine | null {
  const enqyiryLine = getClientDetailsEnquiryLine(enquiry);
  const unsatisfied = enqyiryLine?.questions?.some((q) => !q.isSatisfied);
  return unsatisfied ? enqyiryLine : null;
}

export function isRoot(enquiryLine: EnquiryLine) {
  return enquiryLine.isRoot || enquiryLine.tags?.includes('ISROOT');
}

export function getNonRootEnquiryLines(section: Section): EnquiryLine[] {
  return section.enquiryLines?.filter((enquiryLine) => !isRoot(enquiryLine)) || [];
}

export function getEnquiryLineQuestions(enquiryLines: EnquiryLine[]): Question[] {
  return enquiryLines.flatMap((e) => e.questions || []);
}

export function getSectionQuestions(section: Section): Question[] {
  return getEnquiryLineQuestions(getNonRootEnquiryLines(section));
}

const addDisclosureSectionName = 'Add a disclosure';

export function getAddDisclosureSection(enquiry: Enquiry): Section | null {
  return enquiry.sections.find((section) => section.name === addDisclosureSectionName) || null;
}

export function firstUnsatisfiedEnquiryLine(section: Section | null | undefined): EnquiryLine | null {
  return section?.enquiryLines.find((it) => !it.isSatisfied) || null;
}

export function getEnquiryLineStatus(enquiryLine: EnquiryLine): ProgressStatus {
  if (enquiryLine.isSatisfied) {
    return ProgressStatus.Complete;
  }
  if (enquiryLine.questions?.some((question) => question.hasAnswer)) {
    return ProgressStatus.Incomplete;
  }
  return ProgressStatus.NotStarted;
}

export function getEnquiryStatus(enquiry: Enquiry): ProgressStatus {
  if (enquiry.isSatisfied) {
    return ProgressStatus.Complete;
  }
  if (enquiry.sections.some((section) => section.enquiryLines.some((enquiryLine) => getEnquiryLineStatus(enquiryLine) === ProgressStatus.Incomplete))) {
    return ProgressStatus.Incomplete;
  }
  return ProgressStatus.NotStarted;
}

export function getSectionStatus(section: Section): ProgressStatus {
  if (section.isSatisfied) {
    return ProgressStatus.Complete;
  }
  if (section.enquiryLines?.some(
    (enquiryLine) => getEnquiryLineStatus(enquiryLine) === ProgressStatus.Incomplete || getEnquiryLineStatus(enquiryLine) === ProgressStatus.Complete,
  )) {
    return ProgressStatus.Incomplete;
  }
  return ProgressStatus.NotStarted;
}

function hasTriggerQuestions(enquiryLine: EnquiryLine): boolean {
  return !!enquiryLine.triggerQuestions && !!Object.keys(enquiryLine.triggerQuestions).length;
}

const presaleTag = 'PRESALE';
const presaleHideTag = 'PRESALES_HIDE_NAV';

function isStaticallyTriggeredDislosure(enquiryLine: EnquiryLine): boolean {
  return _.isEmpty(enquiryLine.triggerQuestions)
    && !enquiryLine.tags.includes(presaleTag);
}

function isDisplayable(enquiryLine: EnquiryLine): boolean {
  return hasTriggerQuestions(enquiryLine) || !!enquiryLine.tags?.includes(presaleTag);
}

export function getConditionEnquiryLines(section: Section): EnquiryLine[] {
  return section.enquiryLines.filter((enquiryLine) => isDisplayable(enquiryLine) || isStaticallyTriggeredDislosure(enquiryLine));
}

export function getEnquiryLinesWithQuestions(enquiryLines: EnquiryLine[]): EnquiryLine[] {
  return enquiryLines.filter((enquiryLine) => enquiryLine.questions.length > 0);
}

function shouldHideSectionIfIncludes(enquiryLine: EnquiryLine): boolean {
  return isRoot(enquiryLine)
    || enquiryLine.isWrapUpLine
    || enquiryLine.tags.includes(clientDetailsTag)
    || enquiryLine.tags.includes(presaleHideTag);
}

export function getConditionSections(enquiry: Enquiry, hideEmptyEnquirylines: boolean = false): Section[] {
  return enquiry.sections
    .map((section) => {
      if (!hideEmptyEnquirylines) {
        return section;
      }
      return {
        ...section,
        enquiryLines: getEnquiryLinesWithQuestions(section.enquiryLines),
      };
    })
    .filter((section) => (
      section.name !== addDisclosureSectionName
      && !section.enquiryLines.some((enquiryLine) => shouldHideSectionIfIncludes(enquiryLine))
      && getConditionEnquiryLines(section).length > 0
      && section.enquiryLines.length > 0
    ));
}

export function getUMConditionSections(enquiry: Enquiry): Section[] {
  return [
    {
      name: 'Underwriting questionnaire',
      hasQuestions: true,
      isSatisfied: !enquiry.sections.some((section) => section.isSatisfied === false),
      enquiryLines: [],
    },
  ];
}

export function getConditions(enquiry: Enquiry): EnquiryLine[] {
  return getConditionSections(enquiry)
    .flatMap((section) => getConditionEnquiryLines(section))
    .filter((enquiryLine) => enquiryLine.hasQuestions);
}

export interface NavigableCondition {
  previous: EnquiryLine | null;
  current: EnquiryLine;
  next: EnquiryLine | null;
}

/* eslint-disable no-restricted-syntax */
export function getNavigableConditionByPath(enquiry: Enquiry, path: string): NavigableCondition | null {
  const conditions = getConditions(enquiry);
  for (let i = 0; i < conditions.length; i += 1) {
    if (conditions[i].path === path) {
      return {
        previous: i > 0 ? conditions[i - 1] : null,
        current: conditions[i],
        next: i + 1 < conditions.length ? conditions[i + 1] : null,
      };
    }
  }
  return null;
}

export const genderQuestionName = 'GENDER';
export const birthDateQuestionName = 'BIRTHDATE';
export const smokingQuestionName = 'SMOKING';

export function getQuestionByName(enquiry: Enquiry, name: string): Question | null {
  for (const section of enquiry.sections) {
    for (const enquiryLine of section.enquiryLines) {
      for (const question of enquiryLine.questions) {
        if (question.name === name) {
          return question;
        }
      }
    }
  }
  return null;
}
/* eslint-enable */

export function getAnswersToRemoveCondition(enquiry: Enquiry, condition: EnquiryLine): QuestionAnswerDto[] {
  const triggerQuestionsPaths = hasTriggerQuestions(condition) ? Object.keys(condition.triggerQuestions!) : null;
  const triggers = condition.triggers || [];
  const shouldUpdate = (question: Question) => (
    triggerQuestionsPaths
      ? triggerQuestionsPaths.includes(question.path!)
      : hasAnyAnswersOf(question, triggers)
  );
  const currentPresalesDisclosureIds = enquiry.allAnswers?.[presalesDisclosuresPath] || [];
  let updatedPresalesDisclosureIds = [...currentPresalesDisclosureIds];
  const addDisclosureEnquiryLines = getAddDisclosureSection(enquiry)?.enquiryLines || [];
  const updates: QuestionAnswerDto[] = addDisclosureEnquiryLines.flatMap((enquiryLine) => (
    enquiryLine.questions.flatMap((question) => {
      if (shouldUpdate(question)) {
        const currentAnswer = getMultipleAnswer(question.answers);
        const updatedAnswer = currentAnswer.filter((it) => !triggers.includes(it));
        if (updatedAnswer.length === 0) {
          updatedPresalesDisclosureIds = removeAnswer(enquiryLine.alias!, updatedPresalesDisclosureIds);
        }
        return [{ question: question.path!, answer: updatedAnswer }];
      }
      return [];
    })
  ));
  if (currentPresalesDisclosureIds.length !== updatedPresalesDisclosureIds.length) {
    updates.push({ question: presalesDisclosuresPath, answer: updatedPresalesDisclosureIds });
  }
  return updates;
}

export const heightUnits = 'HEIGHT_UNITS';
export const weightUnits = 'WEIGHT_UNITS';

export const additionalSingleAnswerPaths = [heightUnits, weightUnits];

export function getAdditionalAnswers(enquiry: Enquiry) {
  return additionalSingleAnswerPaths.reduce((values, path) => {
    if (enquiry.allAnswers?.[path] !== undefined) {
      return { ...values, [path]: getSingleAnswer(enquiry.allAnswers?.[path]) };
    }
    return values;
  }, {});
}

export function isEnquiryLineEditable(enquiryLine: EnquiryLine): boolean {
  if (enquiryLine.questions.length > 0) {
    return true;
  }
  return false;
}

export function isEnquiryLineDeletable(enquiryLine: EnquiryLine): boolean {
  if (enquiryLine.triggerQuestions
    && Object.keys(enquiryLine.triggerQuestions).some((triggerQuestion) => triggerQuestion.includes(presalesDisclosuresPath))) {
    return true;
  }
  if (enquiryLine.tags.includes(presaleTag)) {
    return true;
  }
  return false;
}

const exclusiveOptionTag = 'EXCLUSIVE';

export function isOptionExclusive(value: string, exclusiveOptions: string[] | null): boolean {
  return exclusiveOptions?.includes(value) ?? false;
}

export function isOptionExclusiveSet(currentValues: Array<string>, exclusiveOptions: string[] | null): boolean {
  return currentValues.some((currentValue) => isOptionExclusive(currentValue, exclusiveOptions));
}

export function getExclusiveOptions(optionTags: { [key: string]: Array<string> } | null): string[] {
  if (optionTags) {
    return Object.keys(optionTags).filter((optionTag: string) => optionTags[optionTag].includes(exclusiveOptionTag));
  }
  return [];
}

export function formatAnswer(question: Question, answers: { [key: string]: Array<string>; }): string {
  const answer = getSingleAnswer(question.answers);

  if (answer && question.definition.tags.includes('HEIGHT')) {
    if (getSingleAnswer(answers[heightUnits]) === 'imperial') {
      const imperialValue = metersToImperial(parseFloat(answer) ?? 0);
      return `${imperialValue.feet}' ${Math.round(imperialValue.inches!)}"`;
    }
    const centimeterValue = toCentimeters(parseFloat(answer));
    if (centimeterValue) {
      return `${Math.round(centimeterValue).toString()}cm`;
    }
  }
  if (answer && question.definition.tags.includes('WEIGHT')) {
    if (getSingleAnswer(answers[weightUnits]) === 'imperial') {
      const imperialValue = kilogrammsToImperial(parseFloat(answer) ?? 0);
      return `${imperialValue.stones}st ${Math.round(imperialValue.pounds!)}lbs`;
    }
    return `${Math.round(parseFloat(answer)).toString()}kg`;
  }
  if (answer && question.definition.tags.includes('CURRENCY')) {
    return stringToMoney(getSingleAnswer(question.answers) ?? '');
  }
  return answer ?? '';
}

export type GroupedQuestion = Question & {
  groupQuestions?: Question[]
  groupName?: string
};

export function groupQuestionsByTag(questions: Question[], tag: string): GroupedQuestion[] {
  return questions.reduce((acc, curr) => {
    if (curr.definition.tags.includes(tag)) {
      const index = acc.findIndex((question) => question.groupName === tag);
      if (index >= 0) {
        acc[index].groupQuestions?.push(curr);
      } else {
        acc.push({
          ...curr,
          groupName: tag,
          groupQuestions: [curr],
        });
      }
    } else {
      acc.push(curr);
    }
    return acc;
  }, [] as GroupedQuestion[]);
}

export function getGroupQuestionName(question: GroupedQuestion, group: string): string {
  return group.match(/^GROUP\/(.+)/)?.[1] ?? question.name!;
}

export const preDisclosedEnquiryLine = 'Exeter_Past_Medical';
export const preDisclosedConditionOptionList = 'Conditions';

export function getPreDisclosedConditions(enquiryLine: EnquiryLine): string[] {
  return enquiryLine.questions
    .filter((question) => question.definition.optionListName === preDisclosedConditionOptionList)
    .map((question) => question.answers ?? [])
    .flat();
}

export const areAllEnquiriesClosed = (enquiries: Record<string, Enquiry>) => Object.values(enquiries).every((enquiry) => !enquiry.isOpen);
