import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ApplicantDto } from '../../../services/models/applicant-dto';
import { CardTitleWithDetail } from '../../card-title';
import {
  getApplicantAddress,
  getApplicantMedicalDetailsAddress,
  getApplicantName,
  getApplicantTitleFromOptions,
} from '../../../services/application-helpers';
import { ReviewAttribute } from '../review-attribute';
import { ApplicationDto } from '../../../services/models/application-dto';
import Address from '../../address';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useProceed from '../../../hooks/use-proceed';
import { applicantSelected, selectEnquiryFor } from '../../../features/application-slice';
import { ApplicationPage } from '../../../routes/pages';
import { editApplicantView } from '../../../routes/view';
import { asClientDate } from '../../../utils/converters';
import useOptionList from '../../../hooks/use-option-list';
import { OptionDto } from '../../../services/bff/models/option-dto';

export interface ApplicantReviewProps {
  application: ApplicationDto;
  applicant: ApplicantDto;
  readonly?: boolean;
}

function ApplicantReview({
  application,
  applicant,
  readonly = false,
}: ApplicantReviewProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const proceedToNextView = useProceed();
  const address = getApplicantAddress(application, applicant);
  const surgeryAddress = getApplicantMedicalDetailsAddress(application, applicant);
  const enquiry = useAppSelector(selectEnquiryFor(applicant.id));
  const optionList = useOptionList(enquiry);
  const [options, setOptions] = useState<OptionDto[]>([]);

  const getTitleOptions = useCallback(async () => {
    const response = await optionList('TitleOptions');
    setOptions(response.options);
  }, [setOptions]);

  const handleAmend = () => {
    dispatch(applicantSelected(applicant.id));
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id }));
    proceedToNextView({ target: editApplicantView });
  };

  useEffect(() => {
    if (options.length < 0) {
      getTitleOptions();
    }
  }, [application]);

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h3' }}
        title={(
          <CardTitleWithDetail
            title={t('components.applicantReview.header')}
            detail={` - ${getApplicantName(applicant)}`}
          />
        )}
        action={!readonly && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAmend}
            sx={{ marginLeft: 4, paddingY: 0.5 }}
          >
            {t('common.amend')}
          </Button>
        )}
      />
      <CardContent>
        <Typography variant="body1" fontWeight="bold" marginBottom={0.5}>
          {getApplicantName(applicant)}
        </Typography>
        <ReviewAttribute label={t('components.applicantReview.title')} value={getApplicantTitleFromOptions(applicant, options)} />
        <ReviewAttribute label={t('components.applicantReview.firstName')} value={applicant.firstName} />
        <ReviewAttribute label={t('components.applicantReview.lastName')} value={applicant.lastName} />
        <ReviewAttribute label={t('components.applicantReview.sex')} value={t(`common.gender.${applicant.gender}`)} />
        <ReviewAttribute label={t('components.applicantReview.dateOfBirth')} value={asClientDate(applicant.dateOfBirth)} />
        <ReviewAttribute label={t('components.applicantReview.smokerStatus')} value={t(`common.smokerStatus.${applicant.smokerStatus}`)} />
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="body1" fontWeight="bold" marginBottom={0.5}>
          {t('components.applicantReview.contactInformation')}
        </Typography>
        <ReviewAttribute label={t('components.applicantReview.address')} value={<Address address={address} />} />
        <ReviewAttribute label={t('components.applicantReview.postcode')} value={address?.postcode} />
        <ReviewAttribute label={t('components.applicantReview.emailAddress')} value={applicant.contactDetails?.emailAddress} />
        <ReviewAttribute label={t('components.applicantReview.phoneNumber')} value={applicant.contactDetails?.phoneNumber} />
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="body1" fontWeight="bold" marginBottom={0.5}>
          {t('components.applicantReview.doctorDetails')}
        </Typography>
        <ReviewAttribute label={t('components.applicantReview.gpName')} value={applicant.medicalDetails?.gpName} />
        <ReviewAttribute label={t('components.applicantReview.practiceName')} value={applicant.medicalDetails?.practiceName} />
        <ReviewAttribute label={t('components.applicantReview.surgeryAddress')} value={<Address address={surgeryAddress} />} />
        <ReviewAttribute label={t('components.applicantReview.surgeryPostcode')} value={surgeryAddress?.postcode} />
        <ReviewAttribute label={t('components.applicantReview.surgeryPhoneNumber')} value={applicant.medicalDetails?.phoneNumber} />
      </CardContent>
    </Card>
  );
}

export default ApplicantReview;
