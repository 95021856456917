import React from 'react';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Authenticator } from '@aws-amplify/ui-react';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { store } from './store';
import './print.css';
import { awsConfig } from './services/auth-api';
import CacheBuster from './components/cache-buster';
import analyticsService from './services/analytics';
import AppRoutes from './routes/routes';
import CookieNotification from './components/cookie-notification';
import { getUserPreferenceAllowCookies } from './services/profile-helpers';

Amplify.configure(awsConfig);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <CacheBuster>
            {(busy: boolean, version: string | null) => {
              if (!busy && version) {
                if (getUserPreferenceAllowCookies()) {
                  analyticsService.initGA();
                }
                return (
                  <Authenticator.Provider>
                    <AppRoutes />
                  </Authenticator.Provider>
                );
              }
              return null;
            }}
          </CacheBuster>
          <CookieNotification />
        </LocalizationProvider>
      </Provider>
      <ToastContainer newestOnTop />
    </ThemeProvider>
  );
}

export default App;
